import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { useRecoilState, useSetRecoilState, useRecoilValue } from "recoil";
import {
  activeTopicState,
  activeDetailsState,
  sessionState,
  topicSpeakersState,
  voteStatusState,
} from "../../atoms/session.atom";
import {
  menuChoiceState,
  testModeState,
  prevTopicState,
  topicPreviewState,
  topicPreviewDetailsState,
  activeTopicIndex,
  triggerNextTopic,
} from "../../atoms/globals.atom";
import { topicSectionState } from "../../atoms/settings.atom";
import { userState } from "../../atoms/user.atom";

import { FiMoreVertical } from "react-icons/fi";
import { BsChat } from "react-icons/bs";

function TopicCard({
  topic,
  refs,
  timerStart,
  timerStop,
  sendEvent,
  nextTopic,
  number,
  autoAdvance,
}) {
  const [activeTopic, setActiveTopic] = useRecoilState(activeTopicState);
  const [activeIndex, setActiveIndex] = useRecoilState(activeTopicIndex);
  const [triggerNext, setTriggerNext] = useRecoilState(triggerNextTopic);
  const [prevTopic, setPrevTopic] = useRecoilState(prevTopicState);
  const setTopicPreview = useSetRecoilState(topicPreviewState);
  const setActiveDetails = useSetRecoilState(activeDetailsState);
  const sessionData = useRecoilValue(sessionState);
  const setMenuChoice = useSetRecoilState(menuChoiceState);
  const testMode = useRecoilValue(testModeState);
  const voteStatus = useRecoilValue(voteStatusState);
  const [completed, setCompleted] = useState(false);
  const user = useRecoilValue(userState);
  const setTopicSpeakers = useSetRecoilState(topicSpeakersState);
  const setTopicPreviewDetails = useSetRecoilState(topicPreviewDetailsState);
  const setTopicSection = useSetRecoilState(topicSectionState);

  useEffect(() => {
    if (triggerNext && user?.managerRole) {
      if (number === activeIndex + 1) {
        handleTopicChosen();
        setTimeout(() => {
          setTriggerNext(false);
        }, 500);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerNext]);

  useEffect(() => {
    // this is what tells the topic to be complete
    if (prevTopic) {
      prevTopic === topic?._id && setCompleted(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prevTopic]);

  const handleTopicChosen = () => {
    if (!voteStatus) {
      // prevents anything from happening if they hit the same button or a completed item
      let prevEmit = null;
      if (topic._id !== activeTopic && !completed) {
        if (!activeTopic) {
          if (testMode === false) {
            timerStart();
          }
        }
        if (activeTopic) {
          prevEmit = activeTopic;
          if (testMode === false) {
            timerStop();
            timerStop();
          }
          setPrevTopic(activeTopic);
          nextTopic();
          setTopicSection(0);
        }

        setActiveTopic(topic._id);
        setActiveDetails(topic);
        if (topic.speakers.length > 0) {
          setTopicSpeakers(topic.speakers);
        } else {
          setTopicSpeakers([]);
        }
        setMenuChoice(1);
        // auto topic advancing;
        if (autoAdvance) {
          setActiveIndex(number);
        }
        // send event to show the current active topic to other members
        sendEvent("agendaSelected", {
          session: sessionData,
          id: topic._id,
          prevTopic: prevEmit,
          testMode,
        });
      } else if (completed) {
        setTopicPreviewDetails(topic);
        setTopicPreview(true);
      }
    }
  };
  if (user?.managerRole) {
    return (
      <motion.div
        className="w-full mt-2"
        whileHover={{
          scale: 1.01,
          transition: { duration: 0.3 },
        }}
      >
        <div
          className={`h-full w-full border border-gray-400 rounded-lg overflow-hidden cursor-pointer shadow transform duration-200 py-2 z-0 ${
            !completed && "hover:bg-blue-300"
          }  ${topic._id === activeTopic && "bg-blue-300"}`}
          onClick={() => {
            user.managerRole && handleTopicChosen();
          }}
          ref={refs[topic._id]}
        >
          <div className="px-2 py-1 2xl:px-4 2xl:py-2">
            <div className="flex justify-between items-center">
              <h2 className="text-xs 2xl:text-base font-medium text-gray-600 mb-1 title-font">
                {topic.title}
              </h2>
              {completed && (
                <h2 className="text-xs 2xl:text-base font-semibold text-red-900 mb-1 title-font">
                  COMPLETED
                </h2>
              )}
            </div>

            <div className="flex justify-between items-center mt-1 2xl:mt-2">
              <h1 className="text-base 2xl:text-2xl font-medium text-gray-900 title-font">
                {topic.itemNumber}
              </h1>

              {topic.speakers.length > 0 && (
                <div className="flex flex-nowrap items-end">
                  <BsChat className="h-5 w-5 text-gray-800 mr-1" />
                  <p className="text-xs 2xl:text-sm text-gray-800 font-bold">
                    {topic.speakers.length}
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </motion.div>
    );
  } else {
    return (
      <div className="w-full mt-2">
        <div
          className={`h-full w-full border border-gray-400 rounded-lg overflow-hidden shadow transform py-1.5 2xl:py-2 ${
            topic._id === activeTopic && "bg-blue-300"
          }`}
          ref={refs[topic._id]}
        >
          <div className="px-2 py-1 2xl:px-4 2xl:py-2">
            <div className="flex justify-between items-center">
              <h2 className="text-xs 2xl:text-base ont-medium text-gray-600 mb-1 title-font">
                {topic.title}
              </h2>
              <h2 className="text-xs 2xl:text-base font-semibold text-red-900 mb-1 title-font">
                {completed ? "COMPLETED" : ""}
              </h2>
              <FiMoreVertical
                className="h-5 w-5 2xl:h-7 2xl:w-7 text-gray-900 z-50 cursor-pointer hover:text-yellow-500"
                onClick={() => {
                  if (!voteStatus) {
                    setTopicPreviewDetails(topic);
                    setTopicPreview(true);
                  }
                }}
              />
            </div>

            <div className="flex justify-between items-center mt-1 2xl:mt-2">
              <h1 className="text-base 2xl:text-2xl font-medium text-gray-900 title-font">
                {topic.itemNumber}
              </h1>

              {topic.speakers.length > 0 && (
                <div className="flex flex-nowrap items-end">
                  <BsChat className="h-4 w-4 2xl:h-5 2xl:w-5 text-gray-800 mr-1" />
                  <p className="text-xs 2xl:text-sm text-gray-800 font-bold">
                    {topic.speakers.length}
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TopicCard;
