import Papa from "papaparse";

export function parseCSV(file) {
  return new Promise((resolve, reject) => {
    let topicsArray = [];
    // let counter = 1;
    try {
      Papa.parse(file, {
        step: function (result) {
          if (result.errors.length > 0) {
            console.log("error in csv parseCSV step");
            reject("error in csv parseCSV step");
          }
          topicsArray.push({
            title: result.data[0],
            more: result.data[1],
            hasVote: true,
            actionEnabled: true,
            itemNumber: "",
          });
          // topicsArray.push({
          //   itemNumber: result.data[0],
          //   title: result.data[1],
          //   more: result.data[2],
          //   votingOption: result.data[3].toLowerCase(),
          //   count: counter++,
          // });
        },
        complete: function () {
          resolve(topicsArray);
        },
      });
    } catch (error) {
      console.log("error csv parseCSV");
      console.log(error);
    }
  });
}

export const getDate = (text) => {
  try {
    let string = text.substr(0, text.indexOf("."));
    let items = string.split("-");
    // check if it has -
    if (items.length !== 3) {
      return {
        result: false,
        error: "Error in file name. Name needs to be YYYY-MM-DD",
      };
    }

    let year = items[0];
    if (year.length !== 4) {
      return {
        result: false,
        error: "Error in file name. Name needs to be YYYY-MM-DD",
      };
    }

    let month = items[1];
    if (month.length !== 2) {
      return {
        result: false,
        error: "Error in file name. Name needs to be YYYY-MM-DD",
      };
    }
    // let month = Number(items[1]);
    let newMonth = Number(month) - 1;
    // let newMonth = month - 1;

    let day = items[2];
    if (day.length !== 2) {
      return {
        result: false,
        error: "Error in file name. Name needs to be YYYY-MM-DD",
      };
    }
    let date = new Date(year, newMonth, day);
    if (date === "Invalid Date") {
      return {
        result: false,
        error: "Error in file name. Name needs to be YYYY-MM-DD",
      };
    }
    return {
      result: true,
      date,
    };
  } catch (error) {
    console.log("error in get Date");
    return "Error in file date format needs to be YYYY-MM-DD";
  }
};
