import React from "react";

function LiveMotioner({ label, motionedBy, images }) {
  return (
    <div className="flex space-x-2 mt-1 items-center">
      <p className="text-3xl font-medium text-gray-700 italic">{label}:</p>
      <div className="mt-1 relative">
        <div className="relative w-full bg-white pl-3 pr-10 py-2 text-left">
          <span className="flex items-center">
            {!images && (
              <img
                src={motionedBy.photoUrl}
                alt=""
                className="flex-shrink-0 h-20 w-20 rounded-xl"
              />
            )}

            <span className="ml-3 block text-4xl">
              {motionedBy.firstName} {motionedBy.lastName}
            </span>
          </span>
        </div>
      </div>
    </div>
  );
}

export default LiveMotioner;
