import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";

const CompletedDetails = ({ show, visible, topic }) => {
  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed inset-0 overflow-hidden"
        open={show}
        onClose={visible}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-40" />

          <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex sm:pl-16">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="w-screen max-w-2xl bg-gray-50 overflow-y-auto">
                {topic && (
                  <div className="flex-1">
                    {/* Header */}
                    <div className="px-4 py-6 bg-blue-600 sm:px-6">
                      <div className="flex items-start justify-between space-x-3">
                        <div className="space-y-1">
                          <Dialog.Title className="text-lg flex flex-col font-medium text-white">
                            {topic.itemNumber}{" "}
                            <span className="text-base">{topic.title}</span>
                          </Dialog.Title>
                        </div>
                        <div className="h-7 flex items-center">
                          <button
                            type="button"
                            className="rounded-md text-white hover:text-black focus:outline-none focus:ring-2 focus:ring-white"
                            onClick={() => visible(false)}
                          >
                            <span className="sr-only">Close panel</span>
                            <XIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>

                    {/* Divider container */}
                    <div className="py-6 space-y-6 sm:py-0 sm:space-y-0 sm:divide-y sm:divide-gray-200">
                      {/* Project name */}
                      <div className="px-4 sm:space-y-2 flex flex-col sm:px-6 sm:pt-5 sm:pb-2">
                        <div>
                          <label
                            htmlFor="project_name"
                            className="block text-lg font-medium text-gray-900 sm:mt-px sm:pt-2"
                          >
                            Topic Duration:
                          </label>
                        </div>
                        <div className="sm:col-span-2 flex flex-col">
                          <span>{topic.startTime}</span>
                          <span>{topic.endTime}</span>
                        </div>
                      </div>

                      {/* Project description */}
                      <div className="px-4 sm:space-y-2 flex flex-col sm:px-6 sm:py-2">
                        <div>
                          <label
                            htmlFor="project_description"
                            className="block text-lg font-medium text-gray-900 sm:mt-px sm:pt-2"
                          >
                            Details:
                          </label>
                        </div>
                        <div className="sm:col-span-2">{topic.more}</div>
                      </div>

                      {/* Motions */}
                      {topic.motions.length > 0 && (
                        <div className="px-4 sm:space-y-2 flex flex-col sm:px-6 sm:py-2">
                          <div>
                            <h3 className="text-lg font-medium text-gray-900">
                              Motions:
                            </h3>
                          </div>
                          {topic.motionNotes?.length > 0 && (
                            <div>
                              <h3 className="italic text-gray-900">
                                Motion Notes: {topic.motionNotes}
                              </h3>
                            </div>
                          )}
                          <div>
                            {topic.motions.map((motion, index) => (
                              <div
                                className="flex flex-col space-y-2"
                                key={index}
                              >
                                <p className="font-semibold text-base">
                                  {index + 1}. Motion Type: {motion.motionType}
                                </p>
                                <p className="pl-4">
                                  Motioned By: {motion.motionedBy}
                                </p>
                                <p className="pl-4">
                                  Seconded By: {motion.secondedBy}
                                </p>
                                <p className="pl-4">
                                  Vote Result:{" "}
                                  {motion.votingResult === true
                                    ? "Passed"
                                    : "Failed"}
                                </p>
                                <p className="pl-4">Votes:</p>
                                {motion.votes.map((vote, index) => (
                                  <p className="pl-8" key={index}>
                                    <span className="font-medium text-lg">
                                      {vote.member}
                                    </span>{" "}
                                    voted{" "}
                                    <span className="font-medium text-lg">
                                      {vote.vote.charAt(0).toUpperCase() +
                                        vote.vote.slice(1)}
                                    </span>
                                  </p>
                                ))}
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                      {/* Speakers */}
                      <div className="px-4 sm:space-y-2 flex flex-col sm:px-6 sm:py-2">
                        <div>
                          <label
                            htmlFor="project_description"
                            className="block text-lg font-medium text-gray-900 sm:mt-px sm:pt-2"
                          >
                            Speakers:
                          </label>
                        </div>
                        {topic.speakers.map((speaker, index) => {
                          if (
                            speaker.speakerType === "P" ||
                            speaker.speakerType === "S"
                          ) {
                            let position = "For";
                            switch (speaker.position) {
                              case "A":
                                position = "Against";
                                break;
                              case "U":
                                position = "Undecided";
                                break;
                              case "P":
                                position = "General";
                                break;
                              default:
                                break;
                            }
                            return (
                              <div>
                                <p
                                  key={index}
                                  className="text-xs md:text-sm text-black"
                                >
                                  {speaker.firstName} {speaker.lastName}
                                  {speaker.position !== "P" && (
                                    <span> &#40;{position}&#41; </span>
                                  )}
                                  {speaker.representingOrg.length > 0
                                    ? `representing: ${speaker.representingOrg}`
                                    : " "}
                                </p>
                                {speaker.subject.length > 0 && (
                                  <p className="text-xs md:text-sm text-black">
                                    {speaker.subject}
                                  </p>
                                )}
                              </div>
                            );
                          } else {
                            return null;
                          }
                        })}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default CompletedDetails;
