import React, { useState, useEffect } from "react";
import { useSetRecoilState, useRecoilValue } from "recoil";
import DatePicker from "react-datepicker";

import { soloSettingsState, boardsState } from "../../atoms/settings.atom";
import { meetingsListState } from "../../atoms/session.atom";
import { managerUserState } from "../../atoms/user.atom";
import {
  apiCreateSessionHtml,
  apiGetPendingMeetings,
} from "../../api/meetings";

// import ReviewItemCard from "./ReviewItemCard";
import Error from "../Shared/Error";
import HeadlessDrop from "../Shared/DropDown/HeadlessDropBodies";
import NewTopicSlideOut from "../Topics/NewTopicSlideOut";
import EditTopicSlideOut from "../Topics/EditTopicSlideOut";
import TopicCard from "../Topics/TopicCard";

import "react-datepicker/dist/react-datepicker.css";

// TODO create drag and drop to change the order

function MeetingCreate({ prevBoard, isVisible }) {
  const user = useRecoilValue(managerUserState);
  const [scheduleDate, setScheduleDate] = useState(new Date());
  const [topics, setTopics] = useState([]);
  const soloSettings = useRecoilValue(soloSettingsState);
  const boards = useRecoilValue(boardsState);
  const setMeetings = useSetRecoilState(meetingsListState);
  const [errorMsg, setErrorMsg] = useState();
  const [showError, setShowError] = useState(false);
  const [boardFilter, setBoardFilter] = useState(null);
  const [createModal, setCreateModal] = useState(false);
  const [editIndex, setEditIndex] = useState();
  const [editTopic, setEditTopic] = useState();
  const [showEdit, setShowEdit] = useState(false);

  useEffect(() => {
    if (boards) {
      setBoardFilter(boards[0]);
    }
  }, [boards]);

  async function handleFilter(target, value) {
    setBoardFilter(value);
  }

  function handleTopicAdd(topic) {
    setCreateModal(false);
    setTopics((prevState) => [...prevState, { ...topic }]);
  }

  function handleEdit(index, topic) {
    setEditTopic(topic);
    setEditIndex(index);
    setShowEdit(true);
  }

  function handleEditClear() {
    setShowEdit(false);
    setEditIndex();
    setEditTopic();
  }

  function handleTopicUpdate(topic) {
    setShowEdit(false);
    const tempArray = Array.from(topics);
    tempArray.splice(editIndex, 1);
    tempArray.splice(editIndex, 0, topic);
    setTopics(tempArray);
    setEditIndex();
    setEditTopic();
  }

  function handleDelete(index) {
    let tempArray = Array.from(topics);
    tempArray.splice(index, 1);
    setTopics(tempArray);
  }

  async function handleSave() {
    const result = await apiCreateSessionHtml(
      soloSettings.backendServerUrl,
      {
        scheduleDate: scheduleDate,
        board: boardFilter._id,
        actionTopics: topics,
      },
      user?.token
    );
    if (result !== "success") {
      setErrorMsg("Issue with Saving Import");
      setShowError(true);
      setTimeout(() => {
        setShowError(false);
        setErrorMsg();
      }, 3000);
    } else {
      const result = await apiGetPendingMeetings(
        soloSettings.backendServerUrl,
        prevBoard
      );
      setMeetings(result);
    }
    setTopics([]);
    isVisible(false);
  }

  async function handleCancel() {
    setTopics([]);
    isVisible(false);
  }

  return (
    <main
      className="flex-1 relative z-0 overflow-y-auto focus:outline-none"
      tabIndex="0"
    >
      <div className="py-2">
        <div className="flex items-center justify-between border-b-2 max-w-7xl mx-auto px-2 sm:px-6 md:px-4 lg:px-4">
          <h1 className="text-base 2xl:text-2xl font-semibold text-gray-900">
            Create a New Meeting
          </h1>
          <div>
            <button
              type="button"
              className="mr-2 inline-flex items-center px-6 py-2 xl:py-3 mb-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none"
              onClick={() => handleCancel()}
            >
              Cancel
            </button>
            <button
              type="button"
              className="inline-flex items-center px-6 py-2 xl:py-3 mb-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none"
              onClick={() => handleSave()}
            >
              Complete
            </button>
          </div>
        </div>
        <div className="max-w-7xl mx-auto px-2 2xl:px-8 grid grid-cols-2 gap-2 2xl:gap-4">
          {/* Content */}
          <div className="col-span-2 mt-4 flex justify-evenly space-x-10 border-b border-gray-800 pb-2">
            <div className="w-full flex flex-col justify-between">
              {boardFilter && (
                <HeadlessDrop
                  array={boards}
                  value={boardFilter}
                  change={handleFilter}
                  heading="Board"
                />
              )}
              <div>
                <button
                  type="button"
                  className="px-6 py-2 xl:py-3 mb-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-opacity-70 focus:outline-none transform duration-300"
                  onClick={() => setCreateModal(!createModal)}
                >
                  New Topic
                </button>
              </div>
            </div>
            <div className="flex flex-col items-center">
              <h1 className="italic">Scheduled Date:</h1>
              <DatePicker
                selected={scheduleDate}
                onChange={(date) => setScheduleDate(date)}
                inline
              />
            </div>
          </div>
          <div className="col-span-2">
            {topics?.map((topic, index) => {
              return (
                <TopicCard
                  topic={topic}
                  key={index * 3}
                  index={index}
                  setEdit={handleEdit}
                  deleteTopic={handleDelete}
                />
              );
            })}
          </div>
        </div>
      </div>
      {showError && <Error subheading={errorMsg} />}
      {createModal && (
        <NewTopicSlideOut
          isVisible={setCreateModal}
          createTopic={handleTopicAdd}
        />
      )}
      {showEdit && (
        <EditTopicSlideOut
          isVisible={handleEditClear}
          updateTopic={handleTopicUpdate}
          currentTopic={editTopic}
        />
      )}
    </main>
  );
}

export default MeetingCreate;
