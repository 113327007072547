import React, { useState } from "react";
import { useRecoilValue } from "recoil";
import clsx from "clsx";
import { some } from "lodash/collection";

import { micState } from "../../atoms/session.atom";
import { soloSettingsState } from "../../atoms/settings.atom";
import { userState } from "../../atoms/user.atom";

import { IoPersonAddOutline } from "react-icons/io5";

import MicCard from "./MicCard";
import ManagerSeatModal from "../Modals/ManagerSeatModal";
import LogoutModal from "../Modals/LogoutModal";
import RoundTimer from "./SpeakerTimer/RoundTimer";

function MicList({ sendEvent, manager }) {
  const mics = useRecoilValue(micState);
  const user = useRecoilValue(userState);
  const soloSettings = useRecoilValue(soloSettingsState);
  const [loginModal, setLoginModal] = useState(false);
  const [logoutModal, setLogoutModal] = useState(false);

  return (
    <div className=" mr-2 my-2 h-5\/6 bg-white rounded-lg p-2 flex flex-col items-center shadow-md overflow-hidden">
      <div className="w-full flex border-b-2 border-gray-800">
        <p className="text-base 2xl:text-2xl  text-indigo-500 font-medium flex-grow text-center">
          Members
        </p>
        {/* {manager && (
          <IoPersonAddOutline
            className="h-6 w-6 hover:text-green-600 transform duration-300 cursor-pointer hover:scale-110"
            onClick={() => setLoginModal(!loginModal)}
          />
        )} */}
      </div>
      {(user?.managerRole || user?.adminRole) &&
        soloSettings?.memberTimer &&
        soloSettings?.rounds.length > 0 && (
          <div className="mt-1.5">
            <RoundTimer rounds={soloSettings?.rounds} />
          </div>
        )}
      <div
        className={clsx(
          // mics.length >= 8 && "h-full",
          // mics.length < 8 && "h-auto",
          // "relative w-full grid grid-col-1 gap-1 2xl:gap-2 tablet:grid-cols-2 mt-2 overflow-y-auto"
          "relative w-full h-full grid grid-cols-1 tablet:grid-cols-2 place-content-start gap-1 2xl:gap-2 mt-2 overflow-y-auto"
        )}
      >
        {mics?.map((mic, index) => (
          <MicCard
            member={mic}
            key={index}
            sendEvent={sendEvent}
            showLogout={setLogoutModal}
          />
        ))}
        {manager && some(mics, { loggedIn: false }) && (
          <div
            className="h-14 2xl:h-16 hd:h-20 rounded-lg border-2 border-blue-600 bg-transparent group hover:border-blue-400 hover:bg-blue-200 transform duration-300 pr-1 flex items-center justify-center cursor-pointer"
            onClick={() => setLoginModal(!loginModal)}
          >
            <IoPersonAddOutline className="h-6 2xl:h-9 w-6 2xl:w-9 text-blue-600 group-hover:text-blue-400" />
          </div>
        )}
      </div>
      {loginModal && (
        <ManagerSeatModal
          isVisible={setLoginModal}
          users={mics}
          sendEvent={sendEvent}
        />
      )}
      {logoutModal && (
        <LogoutModal isVisible={setLogoutModal} sendEvent={sendEvent} />
      )}
    </div>
  );
}

export default MicList;
