import React from "react";
import clsx from "clsx";
import { useRecoilValue } from "recoil";
import {
  votingResultsState,
  activeDetailsState,
} from "../../atoms/session.atom";

import { soloSettingsState } from "../../atoms/settings.atom";

import VotingResultCard from "./VotingResultCard";

function VotingResults() {
  const votingResults = useRecoilValue(votingResultsState);
  const activeDetails = useRecoilValue(activeDetailsState);
  const soloSettings = useRecoilValue(soloSettingsState);

  return (
    <div
      className={`flex flex-col max-h-screen text-center overflow-hidden h-screen
    ${votingResults.votingResult ? "bg-green-700" : "bg-red-700"}`}
    >
      <div className="grid grid-cols-3 bg-white rounded-lg px-4 pt-5 pb-4 m-4 h-full">
        <div className="grid grid-cols-1 gap-x-2 gap-y-1 col-span-2">
          <div className="flex flex-col flex-nowrap space-y-4 pr-2">
            <div className="inline-flex border-b-2 border-grey-600 pb-2">
              <img
                className="h-24 2xl:h-36 w-auto"
                src={soloSettings.entityLogo}
                alt=""
              />
            </div>
            <div className="inline-flex px-6 justify-between">
              <div>
                <h2 className="hidden lg:block text-center text-2xl 2xl:text-4xl font-semibold text-gray-700 italic">
                  {votingResults.itemNumber}
                </h2>
                <h2 className="hidden lg:block 2xl:mb-6 text-center text-2xl 2xl:text-4xl font-bold text-gray-900 italic">
                  {votingResults.title}
                </h2>
              </div>
              <h2 className="hidden lg:block text-center text-2xl 2xl:text-4xl font-bold text-gray-900 italic">
                {votingResults.motionType} Motion:{" "}
                {votingResults.votingResult ? "Passed" : "Failed"}
              </h2>
            </div>
            <div className="inline-flex px-6 justify-between">
              {activeDetails && (
                <p className="hidden tablet:block text-base tablet:text-lg 2xl:text-2xl font-semibold text-gray-700 text-left leading-relaxed">
                  {activeDetails.more}
                </p>
              )}
            </div>
          </div>
        </div>
        <div
          className={clsx(
            votingResults.votes.length > 10 ? "grid-cols-2" : "grid-cols-1",
            "grid gap-2"
          )}
        >
          {votingResults.votes.map((voter, index) => {
            if (voter) {
              return <VotingResultCard voter={voter} key={index} />;
            } else {
              return null;
            }
          })}
        </div>
      </div>
    </div>
  );
}

export default VotingResults;
