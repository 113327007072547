import React, { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import clsx from "clsx";

import { sessionBoardState } from "../../atoms/session.atom";
import { userState, userMicState } from "../../atoms/user.atom";
import { soloSettingsState, roundTimerState } from "../../atoms/settings.atom";
import { logoutUserState } from "../../atoms/options";

function MicCard({ member, sendEvent, showLogout }) {
  const user = useRecoilValue(userState);
  const { seat } = useParams();
  const intervalRef = useRef(null);
  const counterRef = useRef(2);
  const setUserMic = useSetRecoilState(userMicState);
  const setLogoutUser = useSetRecoilState(logoutUserState);
  const soloSettings = useRecoilValue(soloSettingsState);
  const sessionBoard = useRecoilValue(sessionBoardState);
  const roundTimer = useRecoilValue(roundTimerState);

  useEffect(() => {
    // console.log(member.seat);
    if (user._id === member.id) {
      setUserMic(member.status);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [member]);

  const handleMicPress = async () => {
    let newAction;
    let timer = {
      start: null,
      user: "",
    };
    switch (member.status) {
      case "off":
        newAction = "on";
        if (soloSettings.memberTimer) {
          if (!member.adminRole) {
            if (!member.managerRole) {
              timer = {
                start: true,
                user: member.name,
                time: roundTimer,
              };
            }
          }
        }
        break;
      case "queue":
        newAction = "on";
        if (soloSettings.memberTimer) {
          if (!member.adminRole) {
            if (!member.managerRole) {
              timer = {
                start: true,
                user: member.name,
                time: roundTimer,
              };
            }
          }
        }
        break;
      case "on":
        newAction = "off";
        if (soloSettings.memberTimer) {
          if (!member.adminRole) {
            if (!member.managerRole) {
              timer = {
                start: false,
                user: member.name,
                time: roundTimer,
              };
            }
          }
        }
        break;
      default:
        console.log("wtf happened");
        break;
    }
    sendEvent("micChange", {
      id: member.id,
      action: newAction,
      seat: member.seat,
      board: sessionBoard,
      timer: soloSettings.memberTimer ? timer : null,
    });
  };

  function handleRequest() {
    let newAction;
    let timer = {
      start: false,
      user: "",
    };
    switch (member.status) {
      case "off":
        if (soloSettings.micMode === 1) {
          newAction = "queue";
        } else if (soloSettings.micMode === 2) {
          newAction = "queue";
        } else {
          newAction = "on";
          if (soloSettings.memberTimer) {
            if (!member.adminRole) {
              timer = {
                start: true,
                user: member.name,
                time: roundTimer,
              };
            }
          }
        }
        break;
      case "queue":
        if (soloSettings.micMode === 1) {
          newAction = "off";
          if (soloSettings.memberTimer) {
            if (!member.adminRole) {
              timer = {
                start: false,
                user: member.name,
                time: roundTimer,
              };
            }
          }
        } else if (soloSettings.micMode === 2) {
          newAction = "on";
          if (soloSettings.memberTimer) {
            if (!member.adminRole) {
              timer = {
                start: true,
                user: member.name,
                time: roundTimer,
              };
            }
          }
        } else {
          newAction = "on";
          if (soloSettings.memberTimer) {
            if (!member.adminRole) {
              timer = {
                start: true,
                user: member.name,
                time: roundTimer,
              };
            }
          }
        }
        break;
      case "on":
        newAction = "off";
        if (soloSettings.memberTimer) {
          if (!member.adminRole) {
            timer = {
              start: false,
              user: member.name,
              time: roundTimer,
            };
          }
        }
        break;
      default:
        console.log("wtf happened");
        break;
    }
    sendEvent("micChange", {
      id: user._id,
      seat: seat,
      action: newAction,
      board: sessionBoard,
      timer: soloSettings.memberTimer ? timer : null,
    });
  }

  const startCounter = () => {
    if (intervalRef.current) return;
    intervalRef.current = setInterval(() => {
      if (counterRef.current > 0) {
        counterRef.current = counterRef.current - 1;
      }
      if (counterRef.current === 0) {
        setLogoutUser({ id: member.id, name: member.name });
        showLogout(true);
      }
    }, 1000);
  };

  const stopCounter = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      counterRef.current = 2;
      intervalRef.current = null;
    }
  };

  return (
    <div
      className={clsx(
        member.loggedIn ? "relative" : "hidden",
        member.status === "off" && "bg-gray-200 border border-gray-600",
        member.status === "on" &&
          "bg-green-400 border border-green-800 shadow-md",
        member.status === "queue" && "bg-blue-400 border border-blue-800",
        "h-14 2xl:h-16 hd:h-20 rounded-lg border border-transparent pr-1 flex items-center space-x-1 xl:space-x-2 overflow-hidden"
      )}
      onMouseDown={() => {
        if (user?.managerRole) {
          startCounter();
        }
      }}
      onMouseUp={() => {
        if (user?.managerRole) {
          if (counterRef.current > 0) {
            handleMicPress();
          }
          stopCounter();
        }
      }}
      onMouseLeave={() => {
        if (user?.managerRole) {
          stopCounter();
        }
      }}
    >
      <div className="h-full flex-shrink-0">
        {member.status !== "queue" && (
          <img
            className="h-full w-10 2xl:w-14 rounded-l-lg object-cover"
            src={member.photoUrl}
            alt=""
          />
        )}
        {member.status === "queue" && (
          <div className="h-full w-10 xl:w-12 2xl:w-14 rounded-l-lg bg-main-bg flex items-center justify-center">
            <p className="font-medium text-white text-4xl">
              {member.requestOrder}
            </p>
          </div>
        )}
      </div>
      <div className="flex-1 min-w-0 h-full py-1">
        <button
          className="focus:outline-none h-full w-full flex flex-col overflow-hidden"
          onClick={() => {
            if (user?.adminRole) {
              handleMicPress();
            } else if (user?._id === member.id) {
              if (user?.managerRole === false) {
                handleRequest();
              }
            }
          }}
        >
          <span className="absolute inset-0" aria-hidden="true"></span>
          <p
            className={clsx(
              member.status === "queue" ? "text-white" : "text-gray-900",
              "text-sm 2xl:text-lg font-bold 2xl:font-medium  text-justify truncate 2xl:leading-none"
            )}
          >
            {member.name.split(" ").slice(0, -1).join(" ")}
          </p>
          <p
            className={clsx(
              member.status === "queue" ? "text-white" : "text-gray-900",
              "text-sm 2xl:text-lg font-bold 2xl:font-medium text-justify truncate 2xl:leading-none"
            )}
          >
            {member.name.split(" ").slice(-1).join(" ")}
          </p>
          <p className="hidden hd:block text-tiny 2xl:text-xs text-gray-800 font-light italic text-justify truncate 2xl:leading-none">
            {member.district > 0
              ? `District ${member.district}`
              : `${member.title}`}
          </p>
        </button>
      </div>
    </div>
  );
}

export default MicCard;
