import axios from "axios";

export const apiGetCompleted = async (server, date, board) => {
  try {
    const result = await axios
      .get(`${process.env.REACT_APP_URL}${server}/api/completed`, {
        params: { board, date },
      })
      .then(function (response) {
        return response.data;
      });
    return result;
  } catch (error) {
    console.log("error api completed apiGetCompleted");
    console.log(error);
    return { error: "issue" };
  }
};

export const apiGetSpecific = async (server, id) => {
  try {
    const result = await axios
      .get(`${process.env.REACT_APP_URL}${server}/api/completed/detail/${id}`)
      .then(function (response) {
        return response.data.data;
      });
    return result;
  } catch (error) {
    console.log("error api complete apiGetSpecific");
    console.log(error);
    return { error: "issue" };
  }
};
