import React, { useState, useEffect } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";

import {
  backendServerState,
  boardsState,
  soloSettingsState,
} from "../atoms/settings.atom";
import { importUserState, managerUserState } from "../atoms/user.atom";
import {
  apiGetFilteredUsers,
  apiUpdateUser,
  apiCreateUser,
  apiDeleteUser,
  apiUpdateUserBoard,
} from "../api/members";
import { apiImportUsers } from "../api/granicus";
import { replaceItemAtIndex } from "../helpers/arrays";

import { CloudUploadIcon, UserAddIcon } from "@heroicons/react/outline";

import MemberList from "../components/Members/MemberList";
import MemberReviewList from "../components/Members/MemberReviewList";
import MemberForm from "../components/Members/MemberForm";
import Error from "../components/Shared/Error";
import Success from "../components/Shared/Success";
import DeleteModal from "../components/Members/DeleteModal";
import HeadlessDrop from "../components/Shared/DropDown/HeadlessDrop";
import HeadlessDropBodies from "../components/Shared/DropDown/HeadlessDropBodies";
import Loading from "../components/Modals/Loading";

const stateValues = [
  { id: "active", name: "Active" },
  { id: "inactive", name: "In-Active" },
];

const Members = () => {
  const server = useRecoilValue(backendServerState);
  const boards = useRecoilValue(boardsState);
  const user = useRecoilValue(managerUserState);
  const soloSettings = useRecoilValue(soloSettingsState);
  const [members, setMembers] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [editUser, setEditUser] = useState();
  const [alertSuccess, setAlertSuccess] = useState(false);
  const [alertError, setAlertError] = useState(false);
  const [message, setMessage] = useState({});
  const [verify, setVerify] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [deleteName, setDeleteName] = useState();
  const [memberFilter, setMemberFilter] = useState(stateValues[0]);
  const [boardFilter, setBoardFilter] = useState(null);
  const [loading, setLoading] = useState(false);
  const [reviewImported, setReviewImported] = useState(false);
  const [filterName, setFilterName] = useState("");
  const setImportedUsers = useSetRecoilState(importUserState);

  useEffect(() => {
    async function fetchData() {
      const result = await apiGetFilteredUsers(
        server,
        stateValues[0].id,
        boards[0]._id
      );
      setMembers(result);
      setFiltered(result);
    }
    if (server !== undefined && boards.length > 0) {
      setBoardFilter(boards[0]);
      fetchData();
    }
  }, [server, boards]);

  function handleUserEdit(member) {
    setEditUser(member);
    setShowForm(true);
  }

  async function handleUserDelete(action) {
    if (action.type === "confirm") {
      let result = await apiDeleteUser(server, deleteId, user?.token);
      if (result === "success") {
        const result = await apiGetFilteredUsers(
          server,
          memberFilter.id,
          boardFilter._id
        );
        setMembers(result);
        setFiltered(result);
        setFilterName("");
        setMessage({
          heading: "Success",
          subheading: "User Has Been Deleted",
        });
        handleSuccess();
      } else {
        handleError();
      }
    }
    setVerify(false);
    setDeleteId();
    setDeleteName();
  }

  async function handleMemberForm(action) {
    switch (action.type) {
      case "save":
        if (action.id) {
          let result = await apiUpdateUser(
            server,
            action.id,
            action.data,
            user?.token
          );
          if (result === "success") {
            const result = await apiGetFilteredUsers(
              server,
              memberFilter.id,
              boardFilter._id
            );
            setMembers(result);
            setFiltered(result);
            setFilterName("");
            setMessage({
              heading: "Success",
              subheading: "Changes have been saved",
            });

            setShowForm(false);
            handleSuccess();
          } else {
            handleError();
            console.log(`Error ${result}`);
            setShowForm(false);
          }
        } else {
          let result = await apiCreateUser(server, action.data);
          if (result === "success") {
            const result = await apiGetFilteredUsers(
              server,
              memberFilter.id,
              boardFilter._id
            );
            setMembers(result);
            setFiltered(result);
            setFilterName("");
            setMessage({
              heading: "Success",
              subheading: "New User has been created",
            });
            setShowForm(false);
            handleSuccess();
          } else {
            handleError();
          }
        }
        break;
      case "delete":
        setDeleteId(action.id);
        setDeleteName(action.name);
        setVerify(true);
        break;
      case "password":
        let result = await apiUpdateUser(
          server,
          action.id,
          {
            password: action.password,
          },
          user?.token
        );
        if (result === "success") {
          const result = await apiGetFilteredUsers(
            server,
            memberFilter.id,
            boardFilter._id
          );
          setMembers(result);
          setFiltered(result);
          setFilterName("");
          setMessage({
            heading: "Success",
            subheading: "Changes have been saved",
          });
          setShowForm(false);
          handleSuccess();
        } else {
          handleError();
          console.error(`Error ${result}`);
          setShowForm(false);
        }
        break;
      default:
        break;
    }
    setShowForm(false);
  }

  async function handleFilter(target, value) {
    switch (target) {
      case "status":
        setMemberFilter(value);
        const memberResult = await apiGetFilteredUsers(
          server,
          value.id,
          boardFilter._id
        );
        setMembers(memberResult);
        setFiltered(memberResult);
        setFilterName("");
        break;
      case "board":
        setBoardFilter(value);
        const boardResult = await apiGetFilteredUsers(
          server,
          memberFilter.id,
          value._id
        );
        setMembers(boardResult);
        setFiltered(boardResult);
        setFilterName("");
        break;

      default:
        break;
    }
  }

  async function handleGranicusImport() {
    // console.log(soloSettings);
    setLoading(true);
    const result = await apiImportUsers(server, soloSettings.granicusEntity);
    setImportedUsers(result);
    setLoading(false);
    if (result.length > 0) {
      // This shows the review page
      setReviewImported(true);
    }
  }

  async function handleImportFinish() {
    const result = await apiGetFilteredUsers(
      server,
      memberFilter.id,
      boardFilter._id
    );
    setReviewImported(false);
    setMembers(result);
    setFiltered(result);
    setFilterName("");
  }

  async function handleRefresh(index, key, value) {
    let tempArray = JSON.parse(JSON.stringify(members));
    let changeMe = tempArray[index];
    changeMe = {
      ...changeMe,
      [key]: value,
    };
    const newArray = replaceItemAtIndex(tempArray, index, changeMe);
    setMembers(newArray);
    setFiltered(newArray);
    setFilterName("");
    const result = await apiUpdateUserBoard(
      server,
      changeMe._id,
      {
        board: boardFilter._id,
        options: {
          adminRole: changeMe.adminRole,
          votingRole: changeMe.votingRole,
          defaultSeat: changeMe.defaultSeat,
        },
      },
      user?.token
    );
    if (result === "success") {
      setMessage({
        heading: "Success",
        subheading: "Changes have been saved",
      });
      handleSuccess();
    } else {
      handleError();
    }
  }

  const filter = (e) => {
    const keyword = e.target.value;
    const lowerKey = keyword.toLowerCase();

    if (keyword !== "") {
      const results = filtered.filter((user) => {
        const name = user.firstName + " " + user.lastName;
        let item = name.toLowerCase();
        return item.indexOf(lowerKey) !== -1;
      });
      setFiltered(results);
    } else {
      // If the text field is empty, show all users
      setFiltered(members);
    }

    setFilterName(keyword);
  };

  function handleSuccess() {
    setAlertSuccess(true);
    setTimeout(() => {
      setAlertSuccess(false);
      setMessage({});
    }, [2000]);
  }

  function handleError() {
    setAlertError(true);
    setTimeout(() => {
      setAlertError(false);
      setMessage({});
    }, [2000]);
  }

  return (
    <div className="flex flex-col overflow-hidden h-screen">
      {/* HEADER */}
      <div className="w-full pt-4 pb-2 flex items-center justify-between border-b-2 max-w-7xl mx-auto px-2 sm:px-6 md:px-4">
        <h1 className="text-xl 2xl:text-2xl font-semibold text-gray-900">
          Member Management
        </h1>
        {!showForm && !reviewImported && boards?.length > 0 && (
          <div className="flex items-center">
            {soloSettings.granicusSync && (
              <button
                className="inline-flex items-center px-4 py-3 mr-4 rounded-md bg-indigo-600 hover:bg-opacity-70 text-sm text-white font-medium"
                onClick={() => handleGranicusImport()}
              >
                Import Granicus
                <CloudUploadIcon
                  className="w-5 2xl:h-6 2xl:w-6 h-5 ml-2 -mr-1 text-white-200 hover:text-white-100"
                  aria-hidden="true"
                />
              </button>
            )}
            <button
              type="button"
              className="inline-flex items-center px-4 py-2 xl:px-6 xl:py-3 text-sm rounded-md font-medium text-white bg-indigo-600 hover:bg-opacity-70 focus:outline-none"
              onClick={() => {
                setEditUser();
                setShowForm(true);
              }}
            >
              New member
              <UserAddIcon
                className="w-5 2xl:h-6 2xl:w-6 h-5 ml-2 -mr-1 text-white-200 hover:text-white-100"
                aria-hidden="true"
              />
            </button>
          </div>
        )}
      </div>
      {/* Main Content */}
      <div className="w-full h-full overflow-y-auto max-w-7xl mx-auto mt-6 px-4 sm:px-6 md:px-8 pb-10">
        {showForm ? (
          <MemberForm
            action={handleMemberForm}
            user={editUser}
            server={server}
          />
        ) : (
          <>
            {reviewImported ? (
              <div>
                <MemberReviewList
                  visible={setReviewImported}
                  importFinish={handleImportFinish}
                />
              </div>
            ) : (
              <div>
                <div className="grid md:grid-cols-3 2xl:grid-cols-4 mb-6 gap-4">
                  <HeadlessDrop
                    array={stateValues}
                    value={memberFilter}
                    change={handleFilter}
                    heading="Status"
                  />
                  {boardFilter && (
                    <HeadlessDropBodies
                      array={boards}
                      value={boardFilter}
                      change={handleFilter}
                      heading="Board"
                    />
                  )}
                  <div className="2xl:col-span-2">
                    <p className="italic">Filter</p>
                    <input
                      type="search"
                      value={filterName}
                      onChange={(e) => filter(e)}
                      className="w-full mt-1 shadow-md bg-white text-sm placeholder:text-zinc-400 rounded-md border border-gray-700 focus:outline-none active:outline-none"
                      placeholder="Enter a name..."
                    />
                  </div>
                </div>

                <MemberList
                  members={filtered}
                  edit={handleUserEdit}
                  refresh={handleRefresh}
                />
              </div>
            )}
          </>
        )}
      </div>
      {alertSuccess && (
        <Success heading={message.heading} subheading={message.subheading} />
      )}
      {alertError && (
        <Error subheading="There was an error performing this action" />
      )}
      {verify && <DeleteModal action={handleUserDelete} user={deleteName} />}
      {loading && <Loading />}
    </div>
  );
};

export default Members;
