import React, { Fragment, useState } from "react";
import { useRecoilState } from "recoil";
import { importedTopicsState } from "../../atoms/import.atom";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";

import { replaceItemAtIndex, removeItemAtIndex } from "../../helpers/arrays";

import Toggle from "../Shared/Toggle";

const votingOptions = [
  { id: "none", name: "Non-Voting" },
  { id: "majority", name: "Majority" },
  { id: "supermajority", name: "Super Majority" },
  { id: "unanimous", name: "Unanimous" },
];

function ReviewItemCard({ imported, index }) {
  const [selected, setSelected] = useState(votingOptions[1]);
  const [importedTopics, setImportedTopics] =
    useRecoilState(importedTopicsState);

  function handleChange(e) {
    switch (e.target.name) {
      case "title":
        const newTitle = replaceItemAtIndex(importedTopics, index, {
          ...imported,
          title: e.target.value,
        });
        setImportedTopics(newTitle);
        break;
      case "itemNumber":
        const newItemNumber = replaceItemAtIndex(importedTopics, index, {
          ...imported,
          itemNumber: e.target.value,
        });
        setImportedTopics(newItemNumber);
        break;
      case "details":
        const newMore = replaceItemAtIndex(importedTopics, index, {
          ...imported,
          more: e.target.value,
        });
        setImportedTopics(newMore);
        break;

      default:
        console.log("oops messed up");
        break;
    }
  }

  function handleVoteType(value) {
    setSelected(value);
    const newList = replaceItemAtIndex(importedTopics, index, {
      ...imported,
      votingOption: value.id,
    });
    setImportedTopics(newList);
  }

  function handleVote(value) {
    if (value) {
      setSelected(votingOptions[1]);
    }
    const newList = replaceItemAtIndex(importedTopics, index, {
      ...imported,
      hasVote: value,
      votingOption: value ? "majority" : "none",
    });
    setImportedTopics(newList);
  }

  function handleEnable(value) {
    const newList = replaceItemAtIndex(importedTopics, index, {
      ...imported,
      actionEnabled: value,
    });
    setImportedTopics(newList);
  }

  function deleteTopic() {
    const newList = removeItemAtIndex(importedTopics, index);

    setImportedTopics(newList);
  }

  if (!imported) {
    return <h1>Loading ...</h1>;
  }

  return (
    <div className="bg-white rounded-lg shadow-md p-4 flex flex-col space-y-3 mb-16 border border-asana-bg">
      <div>
        <label className="block text-sm xl:text-lg font-medium text-gray-900 px-2">
          Action Number
          <span className="pl-1 text-xs text-gray-700">
            (If left blank a number will be autogenerated)
          </span>
        </label>
        <input
          type="itemNumber"
          name="itemNumber"
          value={imported.itemNumber}
          className="text-sm xl:text-base 2xl:text-lg mt-1 block w-full border border-gray-300 bg-gray-100 rounded-md shadow-sm py-2 px-3 focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
          onChange={(e) => {
            handleChange(e);
          }}
        />
      </div>
      <div>
        <label className="block text-sm xl:text-lg font-medium text-gray-900 px-2">
          Title
        </label>
        <input
          type="title"
          name="title"
          value={imported.title}
          className="text-sm xl:text-base 2xl:text-lg mt-1 block w-full border border-gray-300 bg-gray-100 rounded-md shadow-sm py-2 px-3 focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
          onChange={(e) => {
            handleChange(e);
          }}
        />
      </div>
      <div>
        <label className="block text-sm xl:text-lg font-medium text-gray-900">
          Details
        </label>
        <div className="mt-1">
          <textarea
            name="details"
            rows="6"
            value={imported.more}
            className="text-sm xl:text-base 2xl:text-lg shadow-sm focus:ring-light-blue-500 focus:border-gray-900 mt-1 block w-full sm:text-sm border border-gray-300 bg-gray-100 rounded-md px-2"
            onChange={(e) => {
              handleChange(e);
            }}
          ></textarea>
        </div>
      </div>
      <div className="flex items-center justify-between">
        <div className="flex flex-col items-center justify-center">
          <Toggle value={imported.actionEnabled} action={handleEnable} />
          <span className="text-sm font-medium text-gray-900">
            Active Topic
          </span>
        </div>
        {imported.actionEnabled && (
          <div className="flex flex-col items-center justify-center">
            <Toggle value={imported.hasVote} action={handleVote} />
            <span className="text-sm font-medium text-gray-900">
              Voting Item
            </span>
          </div>
        )}
      </div>
      {imported.hasVote && (
        <div className="flex flex-col space-y-1">
          <p>Voting Calculation</p>
          <Listbox value={selected} onChange={(e) => handleVoteType(e)}>
            <div className="relative mt-1">
              <Listbox.Button className="relative w-full py-2 pl-3 pr-10 text-left bg-white rounded-lg border border-gray-400 shadow-md cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-orange-300 focus-visible:ring-offset-2 focus-visible:border-indigo-500 sm:text-sm">
                <span className="block truncate">{selected.name}</span>
                <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                  <SelectorIcon
                    className="w-5 h-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>
              <Transition
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  {votingOptions.map((option) => (
                    <Listbox.Option
                      key={option.id}
                      className={({ active }) =>
                        `${
                          active
                            ? "text-amber-900 bg-amber-100"
                            : "text-gray-900"
                        }
                          cursor-default select-none relative py-2 pl-10 pr-4`
                      }
                      value={option}
                    >
                      {({ selected, active }) => (
                        <>
                          <span
                            className={`${
                              selected ? "font-medium" : "font-normal"
                            } block truncate`}
                          >
                            {option.name}
                          </span>
                          {selected ? (
                            <span
                              className={`${
                                active ? "text-amber-600" : "text-amber-600"
                              }
                                absolute inset-y-0 left-0 flex items-center pl-3`}
                            >
                              <CheckIcon
                                className="w-5 h-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </Listbox>
        </div>
      )}
      <div
        className="bg-red-500 h-12 rounded-md flex items-center justify-center cursor-pointer hover:bg-red-600"
        onClick={() => deleteTopic()}
      >
        <h1 className="text-white text-base lg:text-lg">Delete from Import</h1>
      </div>
    </div>
  );
}

export default ReviewItemCard;
