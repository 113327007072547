import React, { useEffect, useState } from "react";
import { useRecoilValue, useRecoilState } from "recoil";
import { soloSettingsState, boardsState } from "../../atoms/settings.atom";

import { apiGetCompleted, apiGetSpecific } from "../../api/completed";
import { apiGetActiveBoards } from "../../api/backend.options";

import { ChevronRightIcon } from "@heroicons/react/solid";

import Loading from "../Modals/Loading";
import DateDropdown from "../Shared/DropDown/DateDropdown";
import CompletedDetails from "./CompletedDetails";
import HeadlessDrop from "../Shared/DropDown/HeadlessDropBodies";

const dates = [
  { name: "Last 3 Months", id: "last3months" },
  { name: "This Year", id: "thisyear" },
  { name: "Last Year", id: "lastyear" },
];

const MeetingsPage = () => {
  const [loading, setLoading] = useState(false);
  const [error, setErrorNotify] = useState(false);
  const [selected, setSelected] = useState();
  const [meetings, setMeetings] = useState([]);
  const soloSettings = useRecoilValue(soloSettingsState);
  const [boards, setBoards] = useRecoilState(boardsState);
  const [boardFilter, setBoardFilter] = useState(null);
  const [dateFilter, setDateFilter] = useState(dates[0]);
  const [activeMeeting, setActiveMeeting] = useState();
  const [topicDetails, setTopicDetails] = useState();
  const [showDetails, setShowDetails] = useState(false);

  useEffect(() => {
    async function fetchData() {
      // setLoading(true);
      let result;
      const boardResult = await apiGetActiveBoards(
        soloSettings.backendServerUrl
      );
      if (Array.isArray(boardResult)) {
        setBoards(boardResult);
        setBoardFilter(boardResult[0]);
        result = await apiGetCompleted(
          soloSettings.backendServerUrl,
          "last3months",
          boardResult[0]._id
        );
      } else {
        result = await apiGetCompleted(
          soloSettings.backendServerUrl,
          "last3months"
        );
      }
      if (result.data) {
        setMeetings(result.data);
      } else {
        setErrorNotify(true);
        setTimeout(() => {
          setErrorNotify(false);
        }, [2000]);
      }
    }
    if (Object.entries(soloSettings).length !== 0) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [soloSettings]);

  const handleChoice = async (id) => {
    setSelected(id);
    setLoading(true);
    const result = await apiGetSpecific(soloSettings.backendServerUrl, id);
    setActiveMeeting(result);
    setLoading(false);
  };

  async function handleFilter(target, value) {
    switch (target) {
      case "filterbydate:":
        setDateFilter(value);
        const result = await apiGetCompleted(
          soloSettings.backendServerUrl,
          value.id,
          boardFilter._id
        );
        if (result.data) {
          setMeetings(result.data);
          setTimeout(() => {
            setLoading(false);
          }, [1000]);
        } else {
          setErrorNotify(true);
          setTimeout(() => {
            setErrorNotify(false);
          }, [2000]);
        }
        break;
      case "boardfilter":
        setBoardFilter(value);
        const filterResult = await apiGetCompleted(
          soloSettings.backendServerUrl,
          dateFilter.id,
          value._id
        );
        if (filterResult.data) {
          setMeetings(filterResult.data);
          setTimeout(() => {
            setLoading(false);
          }, [1000]);
        } else {
          setErrorNotify(true);
          setTimeout(() => {
            setErrorNotify(false);
          }, [2000]);
        }
        break;
      default:
        break;
    }
  }

  function handleChosenTopic(topic) {
    setTopicDetails(topic);
    setShowDetails(true);
  }

  return (
    <div className="mt-4">
      <div className="grid grid-cols-1 gap-4 items-start lg:grid-cols-3 2xl:gap-6">
        {/* Left Side */}
        <div className="grid grid-cols-1 gap-2 2xl:gap-4 shadow-lg">
          <div
            className="bg-white bg-opacity-90 lg:min-w-0 lg:flex-1 rounded-md"
            style={{ minHeight: "40rem" }}
          >
            <div className="px-2 2xl:px-4 py-2 2xl:py-4 border-b border-t border-gray-200 xl:border-t-0">
              <h1 className="text-base xl:text-lg 2xl:text-xl text-center mb-1 font-semibold">
                Completed Meetings
              </h1>
              <div className="grid grid-cols-1 text-xs xl:text-sm 2xl:text-base gap-3">
                <DateDropdown
                  array={dates}
                  value={dateFilter}
                  change={handleFilter}
                  heading="Filter By Date:"
                />
                {boardFilter && (
                  <HeadlessDrop
                    array={boards}
                    value={boardFilter}
                    change={handleFilter}
                    heading="Board Filter"
                  />
                )}
              </div>
            </div>
            <ul className="relative divide-y divide-gray-200 border-b border-gray-200">
              {meetings?.map((item, index) => {
                return (
                  <li
                    className={`${
                      selected === item.id ? "bg-indigo-400" : "bg-gray-50"
                    } rounded-md p-4 flex cursor-pointer transform duration-300 mx-2 my-1 hover:bg-indigo-300`}
                    key={index}
                    onClick={() => handleChoice(item.id)}
                  >
                    <div className="ml-3 flex flex-col">
                      <p className="flex text-gray-900 text-lg space-x-2 items-center">
                        <span className="text-gray-900 text-base 2xl:text-xl font-medium">
                          {item.scheduleDate}
                        </span>
                      </p>
                      <span className="text-gray-900 text-base italic">
                        Duration: {item.startTime} - {item.endTime}
                      </span>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        {/* Right Side */}
        <div className="grid grid-cols-1 col-span-2 gap-2 2xl:gap-4 shadow-lg">
          <div
            className="bg-white bg-opacity-90 rounded-md p-2 2xl:p-4"
            style={{ minHeight: "40rem" }}
          >
            {!activeMeeting && (
              <p className="font-medium text-xl text-center">
                Please Choose a Meeting
              </p>
            )}
            {activeMeeting && (
              <div className="flex flex-col space-y-4 divide-y divide-gray-300">
                <div className="px-4 pt-4 sm:px-6 flex justify-between">
                  <div>
                    <h3 className="text-lg 2xl:text-xl leading-6 font-semibold text-gray-900">
                      {activeMeeting.board}
                    </h3>
                    <h3 className="2xl:text-lg leading-6 text-gray-700">
                      {activeMeeting.scheduleDate}
                    </h3>
                    <p className="mt-1 max-w-2xl text-sm text-gray-700">
                      Duration: {activeMeeting.startTime} -{" "}
                      {activeMeeting.endTime}
                    </p>
                  </div>
                  <div>
                    <a
                      className="inline-flex items-center px-6 py-3 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transform duration-300"
                      href={`http://${soloSettings.backendServerUrl}/api/completed/${activeMeeting.id}/pdf`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        className="-ml-1 mr-3 h-5 w-5"
                      >
                        <path
                          fillRule="evenodd"
                          d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4zm2 6a1 1 0 011-1h6a1 1 0 110 2H7a1 1 0 01-1-1zm1 3a1 1 0 100 2h6a1 1 0 100-2H7z"
                          clipRule="evenodd"
                        />
                      </svg>
                      Minutes PDF
                    </a>
                  </div>
                </div>
                <div className="px-4 pt-2 sm:px-6">
                  <h3 className="text-lg leading-6 font-medium text-gray-900">
                    Locally Attended:
                  </h3>
                  <div className="grid grid-cols-2 lg:grid-cols-3 gap-2">
                    {activeMeeting.localAttended?.map((member) => (
                      <div
                        key={member._id}
                        className="relative pl-1 pr-4 py-2 flex justify-evenly items-center space-x-3"
                      >
                        <div className="flex-shrink-0">
                          <img
                            className="h-10 w-10 2xl:h-12 2xl:w-12 rounded-lg"
                            src={member.photoUrl}
                            alt=""
                          />
                        </div>
                        <div className="flex-1 min-w-0">
                          <p className="text-sm font-medium text-gray-900">
                            {member.firstName} {member.lastName}
                          </p>
                          <p className="text-sm text-gray-500 truncate">
                            {member.title}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                {activeMeeting.remoteAttended.length > 0 && (
                  <div className="px-4 pt-2 sm:px-6">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                      Remotely Attended:
                    </h3>
                    <div className="grid grid-cols-2 lg:grid-cols-3 gap-2">
                      {activeMeeting.remoteAttended?.map((member) => (
                        <div
                          key={member._id}
                          className="relative rounded-lg pl-1 pr-4 py-2 shadow-sm flex justify-evenly items-center space-x-3"
                        >
                          <div className="flex-shrink-0">
                            <img
                              className="h-10 w-10 2xl:h-12 2xl:w-12 rounded-lg"
                              src={member.photoUrl}
                              alt=""
                            />
                          </div>
                          <div className="flex-1 min-w-0">
                            <p className="text-sm font-medium text-gray-900">
                              {member.firstName} {member.lastName}
                            </p>
                            <p className="text-sm text-gray-500 truncate">
                              {member.title}
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}

                <div className="mt-4">
                  <div className="px-4 pt-2 sm:px-6">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                      Topics:
                    </h3>
                  </div>
                  <ul className="divide-y divide-gray-200">
                    {activeMeeting.topics.map((topic, index) => (
                      <li
                        key={topic.id}
                        className={index % 2 === 0 ? "bg-white" : "bg-gray-100"}
                      >
                        <div className="py-2 px-4 md:grid md:grid-cols-4 md:gap-2">
                          <div>
                            <p className="text-sm font-medium text-indigo-600 truncate">
                              {topic.itemNumber}
                            </p>
                            <p className="mt-2 flex items-center text-sm text-black">
                              <span className="truncate">{topic.title}</span>
                            </p>
                          </div>
                          <div className="hidden md:block col-span-2">
                            <p className="text-sm">Details:</p>
                            <p className="text-sm text-black truncate">
                              {topic.more}
                            </p>
                          </div>
                          <div className="flex justify-end my-1">
                            <button
                              className="bg-blue-400 px-4 rounded-md flex items-center text-sm hover:bg-opacity-70 transform duration-300"
                              onClick={() => handleChosenTopic(topic)}
                            >
                              <p className="text-black">More Info</p>
                              <ChevronRightIcon
                                className="h-5 w-5 text-black"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <CompletedDetails
        show={showDetails}
        visible={setShowDetails}
        topic={topicDetails}
      />

      {loading && <Loading />}
      {error && <h1>Error</h1>}
    </div>
  );
};

export default MeetingsPage;
