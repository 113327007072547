import React, { useEffect } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { Switch, Route } from "react-router-dom";

import { getSessionStorage, setSessionStorage } from "./helpers/storage";
import { soloSettingsState } from "./atoms/settings.atom";
import { pluginsState } from "./atoms/options";
import { refreshState } from "./atoms/globals.atom";
import { countdownState } from "./atoms/session.atom";

import { apiGetSettings } from "./api/settings";
// import { useSocket } from "./hooks/useSocket";
import { convertSeconds } from "./helpers/numbers";

import Setup from "./pages/Setup";
import Meetings from "./pages/Meetings";
import RemoteUser from "./pages/RemoteUser";
import MeetingManager from "./pages/MeetingManager";
import MeetingMember from "./pages/MeetingMember";
import Landing from "./pages/Landing";
import Live from "./pages/Live";

const App = () => {
  const setSoloSettings = useSetRecoilState(soloSettingsState);
  const refreshPage = useRecoilValue(refreshState);
  const setPlugins = useSetRecoilState(pluginsState);
  const setCountdown = useSetRecoilState(countdownState);
  // const { sendEvent, nextTopic } = useSocket(soloSettings.backendServerUrl);

  useEffect(() => {
    async function fetchData() {
      // console.log("running apiGetSettings");
      const settings = await apiGetSettings(process.env.REACT_APP_SOLO_ID);
      if (settings) {
        setSoloSettings(settings);
        setSessionStorage("config", settings);
        setCountdown(convertSeconds(settings.timerDefault));
        if (settings.plugins) {
          // console.log("setting plugins");
          setPlugins(JSON.parse(settings.plugins));
        }
      }
    }
    const config = getSessionStorage("config");
    if (!config) {
      fetchData();
    } else {
      setSoloSettings(config);
      setCountdown(convertSeconds(config.timerDefault));
      if (config.plugins) {
        setPlugins(JSON.parse(config.plugins));
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (refreshPage) {
      async function fetchData() {
        // console.log("refreshing config");
        const settings = await apiGetSettings(process.env.REACT_APP_SOLO_ID);
        if (settings) {
          setSoloSettings(settings);
          setCountdown(convertSeconds(settings.timerDefault));
          setSessionStorage("config", settings);
        }
      }

      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshPage]);

  return (
    <Switch>
      <Route path="/setup" exact>
        {/* <Setup sendEvent={sendEvent} /> */}
        <Setup />
      </Route>
      <Route path="/meetings" exact>
        <Meetings />
      </Route>
      <Route path="/live" exact>
        <Live />
      </Route>
      <Route path="/remote" exact>
        <RemoteUser />
        {/* <RemoteUser sendEvent={sendEvent} nextTopic={nextTopic} /> */}
      </Route>
      <Route path="/manager" exact>
        <MeetingManager />
        {/* <MeetingManager sendEvent={sendEvent} nextTopic={nextTopic} /> */}
      </Route>
      <Route path="/manager/:seat">
        <MeetingMember />
        {/* <MeetingMember sendEvent={sendEvent} nextTopic={nextTopic} /> */}
      </Route>
      <Route exact path="/">
        <Landing />
      </Route>
    </Switch>
  );
};

export default App;
