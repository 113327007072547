import React, { useState, useEffect } from "react";
import { useRecoilValue, useRecoilState, useSetRecoilState } from "recoil";
import { soloSettingsState, boardsState } from "../atoms/settings.atom";
import { importReviewState, importedTopicsState } from "../atoms/import.atom";
import { meetingsListState } from "../atoms/session.atom";
import { managerUserState } from "../atoms/user.atom";
import {
  apiGetPendingMeetings,
  apiUpdateMeeting,
  apiDeleteMeeting,
  apiUploadBoardBook,
  apiUploadCivicPlus,
  apiSyncComments,
  apiCloneMeeting,
} from "../api/meetings";
import { apiGranicusPublished } from "../api/granicus";

import { parseCSV } from "../helpers/csv";

import { CloudUploadIcon, PlusIcon } from "@heroicons/react/outline";

import MeetingList from "../components/Meetings/MeetingList";
import DeleteModal from "../components/Meetings/DeleteModal";
import MeetingDetails from "../components/Meetings/MeetingDetails";
import Error from "../components/Shared/Error";
import Success from "../components/Shared/Success";
import Loading from "../components/Modals/Loading";
import MeetingReview from "../components/Meetings/MeetingReview";
import MeetingCreate from "../components/Meetings/MeetingCreate";
import ImportMenu from "../components/Meetings/ImportMenu";
import HeadlessDrop from "../components/Shared/DropDown/HeadlessDropBodies";

const Meetings = () => {
  const user = useRecoilValue(managerUserState);
  const [meetings, setMeetings] = useRecoilState(meetingsListState);
  const boards = useRecoilValue(boardsState);
  const [boardFilter, setBoardFilter] = useState(null);
  const [deleteId, setDeleteId] = useState();
  const [verify, setVerify] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [chosen, setChosen] = useState();
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showCreate, setShowCreate] = useState(false);
  const soloSettings = useRecoilValue(soloSettingsState);
  const setImportedTopics = useSetRecoilState(importedTopicsState);
  const [importReview, setImportReview] = useRecoilState(importReviewState);

  useEffect(() => {
    async function fetchData() {
      const result = await apiGetPendingMeetings(
        soloSettings.backendServerUrl,
        boards[0]._id
      );
      setMeetings(result);
    }
    if (Object.entries(soloSettings).length !== 0 && boards.length > 0) {
      setBoardFilter(boards[0]);
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [soloSettings, boards]);

  async function handleMeetingPress(action) {
    let returnData = { id: action.meeting.id, board: boardFilter._id };
    let updatedMeetings;
    switch (action.type) {
      case "test":
        returnData.active = false;
        returnData.testMode = !action.meeting.testMode;
        updatedMeetings = await apiUpdateMeeting(
          soloSettings.backendServerUrl,
          returnData,
          user?.token
        );
        if (updatedMeetings) {
          setMeetings(updatedMeetings);
          if (soloSettings.speakerComments) {
            setLoading(true);
            // TODO
            // await apiSyncComments(soloSettings.granicusUrl);
            setLoading(false);
          }
        } else {
          // todo error notification
          setErrorMsg("Error Updating Meeting");
          setShowError(true);
          setTimeout(() => {
            setShowError(false);
            setErrorMsg("");
          }, 2000);
        }
        break;
      case "active":
        returnData.active = !action.meeting.active;
        returnData.testMode = false;
        updatedMeetings = await apiUpdateMeeting(
          soloSettings.backendServerUrl,
          returnData,
          user?.token
        );
        if (updatedMeetings) {
          setMeetings(updatedMeetings);
          if (soloSettings.speakerComments) {
            setLoading(true);
            setLoading(false);
          }
        } else {
          setErrorMsg("Error Updating Meeting");
          setShowError(true);
          setTimeout(() => {
            setShowError(false);
            setErrorMsg("");
          }, 2000);
        }

        break;
      case "delete":
        setDeleteId(returnData);
        setVerify(true);
        setChosen();
        break;
      case "details":
        if (soloSettings.speakerComments) {
          if (action.meeting.eventId) {
            setLoading(true);
            await apiSyncComments(soloSettings.backendServerUrl);
            setLoading(false);
          }
        }
        setChosen(action.meeting);
        setShowDetails(true);
        break;
      case "clone":
        // console.log(action);
        // check if Granicus and notify can't because it's granicus
        if (action.meeting.eventId) {
          setErrorMsg(
            "Meeting can't be cloned because it was imported from Granicus."
          );
          setShowError(true);
          setTimeout(() => {
            setShowError(false);
            setErrorMsg("");
          }, 2000);
        } else {
          // api call to clone meeting
          const cloneResult = await apiCloneMeeting(
            soloSettings.backendServerUrl,
            action.meeting.id,
            user?.token
          );
          // success if it works
          if (cloneResult === 200) {
            setShowSuccess(true);
            const result = await apiGetPendingMeetings(
              soloSettings.backendServerUrl,
              boards[0]._id
            );
            setMeetings(result);
            setTimeout(() => {
              setShowSuccess(false);
            }, 2000);
          } else {
            setErrorMsg("There was an error cloning the meeting.");
            setShowError(true);
            setTimeout(() => {
              setShowError(false);
              setErrorMsg("");
            }, 2000);
          }
        }

        break;
      default:
        console.log("oops");
        break;
    }
  }

  async function handleMeetingDelete(action) {
    setVerify(false);
    if (action.type === "confirm") {
      const result = await apiDeleteMeeting(
        soloSettings.backendServerUrl,
        deleteId,
        boardFilter._id,
        user?.token
      );
      if (result === "success") {
        const result = await apiGetPendingMeetings(
          soloSettings.backendServerUrl,
          boardFilter._id
        );
        setMeetings(result);
      } else {
        setErrorMsg("Error in Meeting Delete");
        setShowError(true);
        setTimeout(() => {
          setShowError(false);
          setErrorMsg("");
        }, 2000);
      }
    }
    setDeleteId();
  }

  function handleReturnToList() {
    setShowDetails(false);
    setChosen();
  }

  async function handleImport(target, e) {
    // set a loading icon
    setLoading(true);
    try {
      switch (target) {
        case "csv":
          let topics = await parseCSV(e[0]);
          // console.log(topics);
          if (topics.length > 0) {
            setImportedTopics(topics);
            setShowDetails(false);
            setShowCreate(false);
            setImportReview(true);
          }
          setLoading(false);
          break;
        case "boardbook":
          const boardbook = await apiUploadBoardBook(
            soloSettings.backendServerUrl,
            e[0]
          );
          if (boardbook.length > 0) {
            setImportedTopics(boardbook);
            setShowDetails(false);
            setShowCreate(false);
            setImportReview(true);
          }
          setLoading(false);
          break;
        case "civicplus":
          const civicPlus = await apiUploadCivicPlus(
            soloSettings.backendServerUrl,
            e[0]
          );
          if (civicPlus.length > 0) {
            setImportedTopics(civicPlus);
            setShowDetails(false);
            setShowCreate(false);
            setImportReview(true);
          }
          setLoading(false);
          break;
        default:
          console.log("screwed up somewhere");
          break;
      }
    } catch (error) {
      setLoading(false);
      setErrorMsg("Error in Meeting Import");
      setShowError(true);
      setTimeout(() => {
        setShowError(false);
        setErrorMsg("");
      }, 2000);
    }
  }

  async function handleFilter(target, value) {
    setBoardFilter(value);
    const result = await apiGetPendingMeetings(
      soloSettings.backendServerUrl,
      value._id
    );
    setMeetings(result);
  }

  async function handleGranicusImport() {
    setLoading(true);
    const result = await apiGranicusPublished(
      soloSettings.backendServerUrl,
      soloSettings.granicusEntity
    );
    if (result === "success") {
      const meetingResult = await apiGetPendingMeetings(
        soloSettings.backendServerUrl,
        boardFilter._id
      );
      setMeetings(meetingResult);
    } else {
      setLoading(false);
      setErrorMsg("Error in Meeting Import");
      setShowError(true);
      setTimeout(() => {
        setShowError(false);
        setErrorMsg("");
      }, 2000);
    }
    setLoading(false);
  }

  async function handleRefresh() {
    const meetingResult = await apiGetPendingMeetings(
      soloSettings.backendServerUrl,
      boardFilter._id
    );
    setMeetings(meetingResult);
  }

  function createBlankMeeting() {
    setImportReview(false);
    setShowCreate(true);
  }

  return (
    <div className="flex flex-col overflow-hidden h-screen">
      {/* Header */}
      <div className="w-full pt-4 pb-2 flex items-center justify-between border-b-2 max-w-7xl mx-auto px-2 lg:px-4">
        <h1 className="text-lg xl:text-xl 2xl:text-2xl font-semibold text-gray-900">
          Meeting Management
        </h1>
        {!showDetails && !importReview && !showCreate && boards?.length > 0 && (
          <div className="flex items-center">
            {soloSettings.granicusSync && (
              <button
                className="inline-flex items-center mb-2 px-4 py-3 mr-4 rounded-md bg-indigo-600 hover:bg-opacity-70 text-sm text-white font-medium transform duration-300"
                onClick={() => handleGranicusImport()}
              >
                Import Granicus
                <CloudUploadIcon
                  className="w-5 2xl:h-6 2xl:w-6 h-5 ml-2 -mr-1 text-white-200 hover:text-white-100"
                  aria-hidden="true"
                />
              </button>
            )}
            <ImportMenu handleImport={handleImport} />
            <button
              className="ml-4 inline-flex items-center px-4 py-2 xl:px-6 xl:py-3 rounded-md text-sm font-medium text-white bg-indigo-600 hover:bg-opacity-70 focus:outline-none mb-2 transform duration-300"
              onClick={() => createBlankMeeting()}
            >
              New Meeting
              <PlusIcon
                className="w-5 2xl:h-6 2xl:w-6 h-5 ml-2 -mr-1 text-white-200 hover:text-white-100"
                aria-hidden="true"
              />
            </button>
          </div>
        )}
      </div>
      {/* Main Content */}
      <div className="w-full h-full overflow-y-auto max-w-7xl mx-auto mt-2 2xl:mt-6 px-1 xl:px-8">
        {/* Content */}
        {showDetails && (
          <MeetingDetails
            meeting={chosen}
            exit={handleReturnToList}
            server={soloSettings.backendServerUrl}
            refresh={handleRefresh}
          />
        )}
        {!showDetails && !importReview && !showCreate && (
          <>
            <div className="grid grid-cols-1 xl:grid-cols-3 mb-4">
              {boardFilter && (
                <HeadlessDrop
                  array={boards}
                  value={boardFilter}
                  change={handleFilter}
                  heading="Board Filter"
                />
              )}
            </div>
            <MeetingList meetings={meetings} action={handleMeetingPress} />
          </>
        )}
        {importReview && <MeetingReview prevBoard={boardFilter._id} />}
        {showCreate && (
          <MeetingCreate
            prevBoard={boardFilter._id}
            isVisible={setShowCreate}
          />
        )}
      </div>

      {verify && <DeleteModal action={handleMeetingDelete} />}
      {loading && <Loading />}
      {showError && <Error subheading={errorMsg} />}
      {showSuccess && (
        <Success heading="Success" subheading="Meeting has been cloned" />
      )}
    </div>
  );
};

export default Meetings;
