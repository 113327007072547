import React from "react";
import { useRecoilState, useSetRecoilState } from "recoil";

import {
  soloSettingsState,
  settingsChangedState,
} from "../../atoms/settings.atom";
import { countdownState } from "../../atoms/session.atom";
import { setSessionStorage } from "../../helpers/storage";
import { convertSeconds } from "../../helpers/numbers";

import { RadioGroup } from "@headlessui/react";
import { AiOutlinePlus, AiOutlineDelete } from "react-icons/ai";
import Toggle from "../Shared/Toggle";
import RoundTimer from "./RoundTimer";

const InMeeting = ({ save }) => {
  const [soloSettings, setSoloSettings] = useRecoilState(soloSettingsState);
  const setChanges = useSetRecoilState(settingsChangedState);
  const setCountdown = useSetRecoilState(countdownState);

  function handleRequestToggle(value) {
    setSoloSettings({
      ...soloSettings,
      voteColor: value,
    });
    setSessionStorage("config", { ...soloSettings, voteColor: value });
    setChanges(true);
  }

  function handleAutoClose(value) {
    setSoloSettings({
      ...soloSettings,
      autoVoteClose: value,
    });
    setSessionStorage("config", { ...soloSettings, autoVoteClose: value });
    setChanges(true);
  }

  function handleAutoNextTopic(value) {
    setSoloSettings({
      ...soloSettings,
      autoNextTopic: value,
    });
    setSessionStorage("config", { ...soloSettings, autoNextTopic: value });
    setChanges(true);
  }

  function handleHideImages(value) {
    setSoloSettings({
      ...soloSettings,
      hideImages: value,
    });
    setSessionStorage("config", { ...soloSettings, hideImages: value });
    setChanges(true);
  }

  function handleMemberTime(value) {
    setSoloSettings({
      ...soloSettings,
      memberTimer: value,
    });
    setSessionStorage("config", { ...soloSettings, memberTimer: value });
    setChanges(true);
  }

  function handleMicMode(value) {
    setSoloSettings({
      ...soloSettings,
      micMode: value,
    });
    setSessionStorage("config", { ...soloSettings, micMode: value });
    setChanges(true);
  }

  function handleMotioning(value) {
    setSoloSettings({
      ...soloSettings,
      motioning: value,
    });
    setSessionStorage("config", { ...soloSettings, motioning: value });
    setChanges(true);
  }

  function handleAbstain(value) {
    setSoloSettings({
      ...soloSettings,
      showAbstain: value,
    });
    setSessionStorage("config", { ...soloSettings, showAbstain: value });
    setChanges(true);
  }

  function handleAutoOff(value) {
    setSoloSettings({
      ...soloSettings,
      autoMicOff: value,
    });
    setSessionStorage("config", { ...soloSettings, autoMicOff: value });
    setChanges(true);
  }

  function handleAddRound() {
    // todo update this to soloSettings and sessionStorage
    let tempArray = Array.from(soloSettings.rounds);
    tempArray = [...tempArray, 3];
    setSoloSettings({
      ...soloSettings,
      rounds: tempArray,
    });
    setSessionStorage("config", { ...soloSettings, rounds: tempArray });
    // setRounds(tempArray);
    setChanges(true);
  }

  function handleRound(value, index) {
    // todo update this to soloSettings and sessionStorage
    let tempArray = Array.from(soloSettings.rounds);
    tempArray[index] = value;
    setSoloSettings({
      ...soloSettings,
      rounds: tempArray,
    });
    setSessionStorage("config", { ...soloSettings, rounds: tempArray });
    // setRounds(tempArray);
    setChanges(true);
  }

  function handleDeleteRound(index) {
    let tempArray = Array.from(soloSettings.rounds);
    tempArray.splice(index, 1);
    // todo update this to soloSettings and sessionStorage
    // setRounds(tempArray);
    setSoloSettings({
      ...soloSettings,
      rounds: tempArray,
    });
    setSessionStorage("config", { ...soloSettings, rounds: tempArray });
    setChanges(true);
  }

  return (
    <div className="flex flex-col mt-2 2xl:mt-4 space-y-4 divide-y divide-gray-300">
      {/* Toggle Options */}
      <div className="grid grid-cols-3 gap-2 2xl:gap-4">
        <div className="flex flex-col bg-white shadow-sm rounded-md p-2 border border-gray-400">
          <p className="text-sm xl:text-lg leading-6 font-medium text-gray-900">
            Admin Voter Status
          </p>
          <div className="space-y-6 sm:space-y-5 divide-y divide-gray-200">
            <div className="pt-6 sm:pt-2">
              <Toggle
                value={soloSettings.voteColor}
                action={handleRequestToggle}
              />
              <span className="flex-grow flex flex-col" id="toggleLabel">
                <span className="text-xs xl:text-sm leading-normal text-gray-500 pb-4">
                  Enabling this feature will show how each member voted on the
                  administrator UI. If off they just see a blue status for each
                  member that voted.
                </span>
              </span>
            </div>
          </div>
        </div>
        <div className="flex flex-col bg-white shadow-sm rounded-md p-2 border border-gray-400">
          <p className="text-sm xl:text-lg leading-6 font-medium text-gray-900">
            Auto Close Vote
          </p>

          <div className="space-y-6 sm:space-y-5 divide-y divide-gray-200">
            <div className="pt-6 sm:pt-2">
              <Toggle
                value={soloSettings.autoVoteClose}
                action={handleAutoClose}
              />
              <span className="flex-grow flex flex-col" id="toggleLabel">
                <span className="text-xs xl:text-sm leading-normal text-gray-500 pb-4">
                  When enabled vote will auto-close once all votes are
                  submitted.
                </span>
              </span>
            </div>
          </div>
        </div>
        <div className="flex flex-col bg-white shadow-sm rounded-md p-2 border border-gray-400">
          <p className="text-sm xl:text-lg leading-6 font-medium text-gray-900">
            Auto Topic Advance
          </p>
          <div className="space-y-6 sm:space-y-5 divide-y divide-gray-200">
            <div className="pt-6 sm:pt-2">
              <Toggle
                value={soloSettings.autoNextTopic}
                action={handleAutoNextTopic}
              />
              <span className="flex-grow flex flex-col" id="toggleLabel">
                <span className="text-xs xl:text-sm leading-normal text-gray-500 pb-4">
                  When enabled the next topic will be automatically selected
                  after the voting results clear on a topic with a vote.
                </span>
              </span>
            </div>
          </div>
        </div>
        <div className="flex flex-col bg-white shadow-sm rounded-md p-2 border border-gray-400">
          <p className="text-sm xl:text-lg leading-6 font-medium text-gray-900">
            Member Motioning
          </p>
          <div className="space-y-6 sm:space-y-5 divide-y divide-gray-200">
            <div className="pt-6 sm:pt-2">
              <Toggle value={soloSettings.motioning} action={handleMotioning} />
              <span className="flex-grow flex flex-col" id="toggleLabel">
                <span className="text-xs xl:text-sm leading-normal text-gray-500 pb-4">
                  Enable this feature to allow members to motion themselves.
                  When disabled the meeting manager has to submit all motions.
                </span>
              </span>
            </div>
          </div>
        </div>
        <div className="flex flex-col bg-white shadow-sm rounded-md p-2 border border-gray-400">
          <p className="text-sm xl:text-lg leading-6 font-medium text-gray-900">
            Hide Member Images
          </p>
          <div className="space-y-6 sm:space-y-5 divide-y divide-gray-200">
            <div className="pt-6 sm:pt-2">
              <Toggle
                value={soloSettings.hideImages}
                action={handleHideImages}
              />
              <span className="flex-grow flex flex-col" id="toggleLabel">
                <span className="text-xs xl:text-sm leading-normal text-gray-500 pb-4">
                  When enabled the members photos will not be displayed on the
                  live feed for motioning or voting results.
                </span>
              </span>
            </div>
          </div>
        </div>
        <div className="flex flex-col bg-white shadow-sm rounded-md p-2 border border-gray-400">
          <p className="text-sm xl:text-lg leading-6 font-medium text-gray-900">
            Member Timer
          </p>
          <div className="space-y-6 sm:space-y-5 divide-y divide-gray-200">
            <div className="pt-6 sm:pt-2">
              <Toggle
                value={soloSettings.memberTimer}
                action={handleMemberTime}
              />
              <span className="flex-grow flex flex-col" id="toggleLabel">
                <span className="text-xs xl:text-sm leading-normal text-gray-500 pb-4">
                  Turn this on if you would like to start a timer automatically
                  when turning on a member's microphone
                </span>
              </span>
            </div>
          </div>
        </div>
        <div className="flex flex-col bg-white shadow-sm rounded-md p-2 border border-gray-400">
          <p className="text-sm xl:text-lg leading-6 font-medium text-gray-900">
            Member Abstain
          </p>
          <div className="space-y-6 sm:space-y-5 divide-y divide-gray-200">
            <div className="pt-6 sm:pt-2">
              <Toggle value={soloSettings.showAbstain} action={handleAbstain} />
              <span className="flex-grow flex flex-col" id="toggleLabel">
                <span className="text-xs xl:text-sm leading-normal text-gray-500 pb-4">
                  If this is disabled then member's won't be shown the option to
                  Abstain from a vote.
                </span>
              </span>
            </div>
          </div>
        </div>
        <div className="flex flex-col bg-white shadow-sm rounded-md p-2 border border-gray-400">
          <p className="text-sm xl:text-lg leading-6 font-medium text-gray-900">
            Auto Mics Off
          </p>
          <div className="space-y-6 sm:space-y-5 divide-y divide-gray-200">
            <div className="pt-6 sm:pt-2">
              <Toggle value={soloSettings.autoMicOff} action={handleAutoOff} />
              <span className="flex-grow flex flex-col" id="toggleLabel">
                <span className="text-xs xl:text-sm leading-normal text-gray-500 pb-4">
                  When enabled this will return all mics except for admin and
                  manager to off.
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
      {/* Speaking Rounds */}
      {soloSettings?.memberTimer && (
        <div>
          <div className="pt-4 mb-4 text-lg 2xl:text-xl font-bold">
            <p>Speaking Rounds</p>
            <p className="text-xs 2xl:text-sm text-gray-500 font-light">
              Use this to set how many speaking rounds you would like along with
              the time in minutes for each round. Round selection is then
              available above the list of members while running the meeting.
            </p>
          </div>
          <button
            className="w-9 h-9 rounded-full bg-green-500 hover:bg-green-400 transform duration-200 inline-flex justify-center items-center mb-4"
            onClick={() => {
              handleAddRound();
            }}
          >
            <AiOutlinePlus className="h-5 w-5 text-white" />
          </button>
          {soloSettings?.rounds.map((x, i) => (
            <div key={i + 220} className="flex items-end mb-1.5">
              <p className="2xl:text-lg">
                Round {i + 1}:{" "}
                <span className="font-medium">{x.toString()} minutes</span>
              </p>
              <RoundTimer setRound={handleRound} index={i} />
              <AiOutlineDelete
                className="cursor-pointer h-5 w-5 text-delete-btn mb-2 ml-4 hover:text-delete transform duration-200"
                onClick={() => {
                  handleDeleteRound(i);
                }}
              />
            </div>
          ))}
        </div>
      )}

      {/* Mic Mode */}
      <div>
        <div className="pt-4 mb-4 text-lg 2xl:text-xl font-bold">
          <p>Microphone Mode</p>
        </div>
        <RadioGroup value={soloSettings.micMode} onChange={handleMicMode}>
          <div className="grid grid-cols-1 xl:grid-cols-3 gap-2 xl:gap-4">
            <RadioGroup.Option
              value={1}
              className={({ checked }) =>
                `${
                  checked
                    ? "ring-2 ring-offset-4 ring-offset-blue-300 ring-blue-900"
                    : ""
                }
                  ${
                    checked
                      ? "bg-blue-900 bg-opacity-75 text-white"
                      : "bg-gray-300 text-black"
                  }
                    relative rounded-lg shadow-md px-5 py-4 cursor-pointer flex focus:outline-none`
              }
            >
              {({ checked }) => (
                <>
                  <div className="flex justify-between w-full">
                    <div className="flex">
                      <div className="text-sm 2xl:text-base">
                        <RadioGroup.Label
                          as="p"
                          className={`font-medium text-base 2xl:text-lg mb-2 ${
                            checked ? "text-white" : "text-gray-900"
                          }`}
                        >
                          Request to Speak
                        </RadioGroup.Label>
                        <RadioGroup.Description
                          as="span"
                          className={`inline ${
                            checked ? "text-blue-100" : "text-gray-800"
                          }`}
                        >
                          <span>
                            Members request to speak. Only the meeting manager
                            and meeting admin have the ability to turn their
                            microphones on
                          </span>
                        </RadioGroup.Description>
                      </div>
                    </div>
                    {/* {checked && (
                        <div className="flex-shrink-0 text-white">
                          <CheckIcon className="w-6 h-6" />
                        </div>
                      )} */}
                  </div>
                </>
              )}
            </RadioGroup.Option>
            <RadioGroup.Option
              value={2}
              className={({ checked }) =>
                `${
                  checked
                    ? "ring-2 ring-offset-4 ring-offset-blue-300 ring-blue-900"
                    : ""
                }
                  ${
                    checked
                      ? "bg-blue-900 bg-opacity-75 text-white"
                      : "bg-gray-300 text-black"
                  }
                    relative rounded-lg shadow-md px-5 py-4 cursor-pointer flex focus:outline-none`
              }
            >
              {({ checked }) => (
                <>
                  <div className="flex justify-between w-full">
                    <div className="flex">
                      <div className="text-sm 2xl:text-base">
                        <RadioGroup.Label
                          as="p"
                          className={`font-medium text-base 2xl:text-lg mb-2 ${
                            checked ? "text-white" : "text-gray-900"
                          }`}
                        >
                          Request and Mic
                        </RadioGroup.Label>
                        <RadioGroup.Description
                          as="span"
                          className={`inline ${
                            checked ? "text-blue-100" : "text-gray-800"
                          }`}
                        >
                          <span>
                            When members first press the request to speak button
                            it places them in the queue. They can then press the
                            button again to activate their microphone.
                          </span>
                        </RadioGroup.Description>
                      </div>
                    </div>
                    {/* {checked && (
                        <div className="flex-shrink-0 text-white">
                          <CheckIcon className="w-6 h-6" />
                        </div>
                      )} */}
                  </div>
                </>
              )}
            </RadioGroup.Option>
            <RadioGroup.Option
              value={3}
              className={({ checked }) =>
                `${
                  checked
                    ? "ring-2 ring-offset-4 ring-offset-blue-300 ring-blue-900"
                    : ""
                }
                  ${
                    checked
                      ? "bg-blue-900 bg-opacity-75 text-white"
                      : "bg-gray-300 text-black"
                  }
                    relative rounded-lg shadow-md px-5 py-4 cursor-pointer flex focus:outline-none`
              }
            >
              {({ checked }) => (
                <>
                  <div className="flex justify-between w-full">
                    <div className="flex">
                      <div className="text-sm 2xl:text-base">
                        <RadioGroup.Label
                          as="p"
                          className={`font-medium text-base 2xl:text-lg mb-2 ${
                            checked ? "text-white" : "text-gray-900"
                          }`}
                        >
                          Microphone Only
                        </RadioGroup.Label>
                        <RadioGroup.Description
                          as="span"
                          className={`inline ${
                            checked ? "text-blue-100" : "text-gray-800"
                          }`}
                        >
                          <span>
                            Members simply press their Microphone button to turn
                            their mics on and off. There is no queue with this
                            mode.
                          </span>
                        </RadioGroup.Description>
                      </div>
                    </div>
                    {/* {checked && (
                        <div className="flex-shrink-0 text-white">
                          <CheckIcon className="w-6 h-6" />
                        </div>
                      )} */}
                  </div>
                </>
              )}
            </RadioGroup.Option>
          </div>
        </RadioGroup>
      </div>
      {/* Timer Adjustments */}
      <div className="pt-4 grid grid-cols-3 gap-2">
        <div className="col-span-3 pt-4">
          <h3 className="text-base xl:text-lg 2xl:text-xl leading-6 font-bold text-gray-900">
            Timer Adjustments (All values are in seconds)
          </h3>
        </div>

        <div className="flex flex-col space-y-1">
          <div className="mt-1 sm:mt-0 xl:col-span-2">
            <input
              type="number"
              min="5"
              max="60"
              id="liveResultsTimer"
              value={soloSettings.liveResultsTimer}
              className="flex-1 block w-full rounded-md shadow-sm py-2 px-3 sm:text-base border border-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              onChange={(e) => {
                setSoloSettings({
                  ...soloSettings,
                  liveResultsTimer: e.target.value,
                });
                setSessionStorage("config", {
                  ...soloSettings,
                  liveResultsTimer: e.target.value,
                });
                setChanges(true);
              }}
            />
          </div>
          <label className="block text-sm xl:text-base 2xl:text-lg font-medium text-gray-700 sm:mt-px sm:pt-2">
            Voting Results Live Output
          </label>
        </div>

        <div className="flex flex-col space-y-1">
          <div className="mt-1 sm:mt-0 xl:col-span-2">
            <input
              type="number"
              min="1"
              max="60"
              id="memberResultsTimer"
              value={soloSettings.memberResultsTimer}
              className="flex-1 block w-full rounded-md shadow-sm py-2 px-3 sm:text-base border border-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              onChange={(e) => {
                setSoloSettings({
                  ...soloSettings,
                  memberResultsTimer: e.target.value,
                });
                setSessionStorage("config", {
                  ...soloSettings,
                  memberResultsTimer: e.target.value,
                });
                setChanges(true);
              }}
            />
            <label className="block text-sm xl:text-base 2xl:text-lg  font-medium text-gray-700 sm:mt-px sm:pt-2">
              Voting Results Member Screens
            </label>
          </div>
        </div>

        <div className="flex flex-col space-y-1">
          <div className="mt-1 sm:mt-0 xl:col-span-2">
            <input
              type="number"
              min="180"
              max="360"
              id="timerDefault"
              value={soloSettings.timerDefault}
              className="flex-1 block w-full rounded-md shadow-sm py-2 px-3 sm:text-base border border-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              onChange={(e) => {
                setSoloSettings({
                  ...soloSettings,
                  timerDefault: e.target.value,
                });
                setSessionStorage("config", {
                  ...soloSettings,
                  timerDefault: e.target.value,
                });
                setCountdown(convertSeconds(e.target.value));
                setChanges(true);
              }}
            />
            <label className="block text-sm xl:text-base 2xl:text-lg  font-medium text-gray-700 sm:mt-px sm:pt-2">
              Public Speaker Timer
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InMeeting;
