import React from "react";

function LiveDetails({ details }) {
  React.useEffect(() => {
    if (details) {
      let element = document.getElementById("data");
      if (element) {
        const { scrollHeight, offsetHeight } = element;
        if (scrollHeight > offsetHeight) {
          element.style.setProperty("font-size", "2.5rem", "important");
        } else {
          element.style.setProperty("font-size", "3rem", "important");
        }
      }
      let previous = "2.5rem";
      let timer = setInterval(() => {
        let compare = document.getElementById("data");
        if (compare.scrollHeight !== compare.offsetHeight) {
          switch (previous) {
            case "2.5rem":
              element.style.setProperty("font-size", "2rem", "important");
              previous = "2rem";
              break;
            case "2rem":
              element.style.setProperty("font-size", "1.5rem", "important");
              previous = "1.5rem";
              break;
            default:
              console.log("shouldn't have to shrink it more");
              break;
          }
        }
      }, 500);
      return () => {
        clearInterval(timer);
      };
    }
  }, [details]);

  return (
    <div className="px-6 text-5xl transform duration-200" id="data" style={{ height: "42rem" }}>
      {details && (
        <p className="font-medium text-gray-900 text-justify leading-relaxed">
          {details}
        </p>
      )}
    </div>
  );
}

export default LiveDetails;
