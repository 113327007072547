import React from "react";

const SpeakerCards = ({ cards, setShow, update }) => {
  return (
    <div className="fixed z-50 inset-0 overflow-y-auto">
      <div className="flex items-end justify-center min-h-screen pt-4 px-10 2xl:px-4 pb-20 text-center sm:block">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>

        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>

        <div
          className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-5xl sm:w-full sm:p-6"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
            <button
              type="button"
              className="bg-white rounded-md text-gray-900 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              onClick={() => setShow(false)}
            >
              <svg
                className="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <div className="sm:flex sm:items-start">
            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                There {cards.length === 1 ? "is" : "are"} {cards.length}{" "}
                registered {cards.length === 1 ? "speaker" : "speakers"}.
              </h3>
              <div className="mt-2">
                <ul className="divide-y-2 divide-gray-200 w-full">
                  {cards.map((card, index) => {
                    let attend;
                    card.attendOrCall === "A"
                      ? (attend = "Attending")
                      : (attend = "Calling");
                    return (
                      <li className="py-1 2xl:py-2 flex" key={index}>
                        <div className="mx-3 w-full">
                          <div className="flex justify-between">
                            <p className="text-base 2xl:text-lg font-medium text-indigo-600">
                              {card.position === "F" && (
                                <span className="flex-shrink-0 inline-block px-2 py-0.5 text-green-800 text-xs 2xl:text-sm font-medium bg-green-100 rounded-full">
                                  FOR
                                </span>
                              )}
                              {card.position === "A" && (
                                <span className="flex-shrink-0 inline-block px-2 py-0.5 text-red-800 text-xs 2xl:text-sm font-medium bg-red-100 rounded-full">
                                  AGAINST
                                </span>
                              )}
                              {card.position === "U" && (
                                <span className="flex-shrink-0 inline-block px-2 py-0.5 text-gray-800 text-xs 2xl:text-sm font-medium bg-gray-100 rounded-full">
                                  UNDECIDED
                                </span>
                              )}
                              {card.firstName} {card.lastName}{" "}
                              {card.district.length > 0 && (
                                <span className="text-gray-800">
                                  (District {card.district})
                                </span>
                              )}
                            </p>
                          </div>
                          <p className="text-base 2xl:text-lg font-medium text-gray-900">
                            {card.address}
                          </p>
                          {card.address.length > 0 && (
                            <p className="text-base 2xl:text-lg font-medium text-gray-900">
                              {card.city}, {card.state} {card.zip}
                            </p>
                          )}

                          <p className="text-base 2xl:text-lg italic font-medium text-gray-700">
                            {attend}
                          </p>
                          {card.oppositionOnly && (
                            <p className="text-base 2xl:text-lg font-medium text-red-600">
                              Oppostion Only
                            </p>
                          )}
                          {card.representing.length > 0 && (
                            <p className="text-base 2xl:text-lg font-medium text-gray-900">
                              Representing: {card.representing}
                            </p>
                          )}
                          {card.representingOrg.length > 0 && (
                            <p className="text-base 2xl:text-lg font-medium text-gray-900">
                              Representing Org: {card.representingOrg}
                            </p>
                          )}
                        </div>
                        {card.manuallyAdded && (
                          <div className="flex items-center space-x-2">
                            <button
                              className="rounded-lg bg-blueGray-500 text-white px-4 py-2 hover:bg-opacity-70"
                              onClick={() => {
                                update("edit", card);
                                setShow(false);
                              }}
                            >
                              Edit
                            </button>
                            <button
                              className="rounded-lg bg-red-700 text-white px-4 py-2 hover:bg-opacity-70"
                              onClick={() => {
                                update("delete", card);
                                setShow(false);
                              }}
                            >
                              Delete
                            </button>
                          </div>
                        )}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SpeakerCards;
