import React, { Fragment } from "react";
import { useRecoilValue } from "recoil";
import {
  // voterState,
  activeTopicState,
  motionedByState,
  motionListState,
  activeMotionState,
} from "../../atoms/session.atom";
import { userState } from "../../atoms/user.atom";
import { testModeState } from "../../atoms/globals.atom";

import { Menu, Transition } from "@headlessui/react";

function MotionButton({ sendEvent }) {
  const user = useRecoilValue(userState);
  const activeTopic = useRecoilValue(activeTopicState);
  const testMode = useRecoilValue(testModeState);
  const motionedBy = useRecoilValue(motionedByState);
  const motionList = useRecoilValue(motionListState);
  const activeMotion = useRecoilValue(activeMotionState);

  function handleNewMotion(value) {
    sendEvent("newMotion", {
      topic: activeTopic,
      member: user._id,
      level: 1,
      test: testMode,
      motionType: value,
    });
  }

  function handleSecondMotion() {
    sendEvent("newMotion", {
      topic: activeTopic,
      member: user._id,
      level: 2,
      test: testMode,
      motion: activeMotion,
    });
  }

  if (motionedBy && motionedBy.length === 0) {
    return (
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button className="inline-flex items-center justify-center w-full px-4 py-2 text-sm xl:text-base 2xl:text-lg font-medium text-white rounded-md bg-gradient-to-r from-green-500 to-green-600 hover:from-green-400 hover:to-green-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
            Make Motion
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute w-40 2xl:w-52 origin-top-right mt-1 px-2 py-2 bg-gray-200 divide-y divide-gray-300 rounded-md shadow-2xl focus:outline-none border border-gray-200">
            {motionList?.map((item, index) => (
              <Menu.Item
                className="text-sm xl:text-base 2xl:text-lg py-2 px-2 cursor-pointer hover:bg-white rounded-md"
                key={index}
                onClick={() => handleNewMotion(item)}
              >
                <p>{item}</p>
              </Menu.Item>
            ))}
          </Menu.Items>
        </Transition>
      </Menu>
    );
  }

  if (motionedBy && motionedBy.length === 1) {
    return (
      <>
        {motionedBy[0]._id !== user._id && (
          <div className="relative inline-block text-left">
            <button
              className="inline-flex items-center justify-center w-full px-4 py-2 text-sm xl:text-base 2xl:text-lg font-medium text-white rounded-md bg-gradient-to-r from-green-500 to-green-600 hover:from-green-400 hover:to-green-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
              onClick={() => handleSecondMotion()}
            >
              Second Motion
            </button>
          </div>
        )}
      </>
    );
  }

  if (motionedBy && motionedBy.length === 2) {
    return null;
  }
}

export default MotionButton;
