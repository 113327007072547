import React, { useState, useEffect } from "react";
import { useRecoilState, useSetRecoilState, useRecoilValue } from "recoil";
import clsx from "clsx";

import { useSocket } from "../hooks/useSocket";
import { userState } from "../atoms/user.atom";
import {
  sessionState,
  topicState,
  micState,
  voterState,
  enableLoginState,
  votingResultsState,
  motionListState,
  endingNotesState,
  sessionBoardState,
  consentTopicState,
} from "../atoms/session.atom";

import {
  testModeState,
  menuChoiceState,
  topicPreviewState,
  endingNotesModalState,
  speakerStatusState,
} from "../atoms/globals.atom";
import { soloSettingsState } from "../atoms/settings.atom";
//hooks
import { useAgendaTimer } from "../hooks/useAgendaTimer";
import { apiAuthLogin, apiUserLogout } from "../api/login";
// import { apiGetSingleUser } from "../api/members";
import { apiGetActiveSession, apiGetInProgress } from "../api/meetings";
import { setSessionStorage, deleteSessionStorage } from "../helpers/storage";

import { BsChevronBarRight, BsChevronBarLeft } from "react-icons/bs";

// components
import TopicList from "../components/InMeeting/TopicListMember";
import MicList from "../components/InMeeting/MicListMember";
import LoginModal from "../components/Login/MemberLogin";
import Loading from "../components/Modals/Loading";
import NoActiveMeeting from "../components/Modals/NoActiveMeeting";
import RequestToSpeak from "../components/InMeeting/RequestToSpeak";
import MicOnly from "../components/InMeeting/MicOnly";
import RequestAndMic from "../components/InMeeting/RequestAndMic";
import TopicDetails from "../components/InMeeting/TopicDetails";
import VotingResults from "../components/InMeeting/VotingResults";
import SpeakerTimer from "../components/InMeeting/SpeakerTimer/SpeakerTimer";
import ModalTopicDetails from "../components/Modals/ModalTopicDetails";
import EndingNotesModal from "../components/Modals/AdminEndingNotesModal";
import TimerActive from "../components/InMeeting/TimerActive";

const RemoteUser = () => {
  const [user, setUser] = useRecoilState(userState);
  const [errorNotify, setErrorNotify] = useState(false);
  const [left, setLeft] = useState(true);
  const [right, setRight] = useState(true);
  const [testMode, setTestMode] = useRecoilState(testModeState);
  const [sessionData, setSessionData] = useRecoilState(sessionState);
  const setTopicsState = useSetRecoilState(topicState);
  const setConsentTopics = useSetRecoilState(consentTopicState);
  const setMicsState = useSetRecoilState(micState);
  const setVoterState = useSetRecoilState(voterState);
  const setEndingNotes = useSetRecoilState(endingNotesState);
  const [sessionBoard, setSessionBoard] = useRecoilState(sessionBoardState);
  const [enableLogin, setEnableLogin] = useRecoilState(enableLoginState);
  const votingResults = useRecoilValue(votingResultsState);
  const [choice, setChoice] = useRecoilState(menuChoiceState);
  const soloSettings = useRecoilValue(soloSettingsState);
  const topicPreview = useRecoilValue(topicPreviewState);
  const endingNotesModal = useRecoilValue(endingNotesModalState);
  const setMotionList = useSetRecoilState(motionListState);
  const speakerStatus = useRecoilValue(speakerStatusState);
  const { sendEvent, nextTopic } = useSocket(
    soloSettings.backendServerUrl,
    "/remote"
  );
  const { start, stop } = useAgendaTimer(sendEvent);

  useEffect(() => {
    async function checkInProgress() {
      const inProgress = await apiGetInProgress(soloSettings.backendServerUrl);
      if (!Array.isArray(inProgress)) {
        setTestMode(inProgress.testMode);
        setEnableLogin(true);
        // set board for active meeting so login is correct
        setSessionBoard(inProgress.board);
      }
    }
    if (Object.entries(soloSettings).length !== 0) {
      checkInProgress();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [soloSettings]);

  const fetchData = async () => {
    try {
      const result = await apiGetActiveSession(soloSettings.backendServerUrl);
      // console.log(result);
      if (result !== "nothing") {
        setTopicsState(result.actionTopics);
        setConsentTopics(result.consentTopics);
        setSessionData(result._id);
        setSessionBoard(result.board);
        setTestMode(result.testMode);
        if (result.endingNotes) {
          if (result.endingNotes.length > 0) {
            setEndingNotes(result.endingNotes);
          }
        }
        let newArray = [];
        let newArray2 = [];
        let motionArray = [];
        for (const member of result.invited) {
          let data = {};
          data.id = member._id;
          data.photoUrl = member.photoUrl;
          data.name = member.firstName + " " + member.lastName;
          data.status = member.micStatus;
          data.loggedIn = member.loggedIn;
          data.seat = member.seat;
          data.district = member.district;
          data.title = member.title;
          data.requestOrder = member.requestOrder;
          data.adminRole = member.adminRole;
          newArray.push(data);
          if (member.votingRole) {
            let votingData = {};
            votingData.id = member._id;
            votingData._id = member._id;
            votingData.photoUrl = member.photoUrl;
            votingData.name = member.firstName + " " + member.lastName;
            votingData.voted = "none";
            votingData.loggedIn = member.loggedIn;
            votingData.district = member.district;
            votingData.adminRole = member.adminRole;
            newArray2.push(votingData);
          }
        }
        for (const motion of result.motionList) {
          motionArray.push(motion.name);
        }
        setMotionList(motionArray);
        setMicsState(newArray);
        setVoterState(newArray2);
      } else {
        setSessionData("noMeeting");
      }
    } catch (error) {
      console.log("error RemoteUser fetchData");
      console.log(error);
    }
  };

  const handleUserLogin = async (email, pass) => {
    const result = await apiAuthLogin(
      soloSettings.backendServerUrl,
      email,
      pass,
      "0",
      sessionBoard
    );
    if (result !== "error") {
      setUser(result);
      setSessionStorage("user", result);
      await fetchData();
      sendEvent("memberLoggedIn", {
        id: result._id,
        seat: "0",
        testMode,
        remote: "true",
        board: sessionBoard,
      });
    } else {
      setErrorNotify(true);
      setTimeout(() => {
        setErrorNotify(false);
      }, 2000);
    }
  };

  const handleManualLogin = async (user) => {
    // const result = await apiGetSingleUser(soloSettings.backendServerUrl, user);
    // if (result) {
    //   setUser(result);
    //   setSessionStorage("user", result);
    //   // fetch meeting data
    //   await fetchData();
    // }
  };

  const handleSignOut = async () => {
    await apiUserLogout(soloSettings.backendServerUrl, user._id);
    sendEvent("memberLoggedOut", {
      id: user._id,
      board: sessionBoard,
    });
    deleteSessionStorage("user");
    setTimeout(() => {
      setUser();
    }, 750);
  };

  if (!user && !enableLogin) {
    return <NoActiveMeeting />;
  }

  if (!user && enableLogin) {
    return (
      <LoginModal
        login={handleUserLogin}
        notify={errorNotify}
        seat="0"
        manualLogin={handleManualLogin}
      />
    );
  }
  if (user && !sessionData) {
    return <Loading />;
  }
  if (user && sessionData === "noMeeting") {
    return (
      <div className="grid grid-cols-4 gap-2 overflow-hidden h-screen bg-gray-400 max-h-screen">
        <div className="ml-2 my-2 h-5\/6 max-h-3\.5 bg-white rounded-lg p-2 flex flex-col items-center shadow-md overflow-hidden">
          <p className="text-base xl:text-2xl border-b-2 border-gray-800 text-indigo-500 font-medium">
            Agenda Items
          </p>
          <div className="w-full overflow-y-auto"></div>
        </div>
        <div className="my-2 col-span-2 h-5\/6 bg-white rounded-lg p-2 flex flex-col items-center shadow-md justify-between">
          <p className="text-base xl:text-2xl border-b-2 border-gray-800 text-indigo-500 font-medium">
            AgendaLink Solo Meeting Manager
          </p>
          <div className="flex flex-col items-center justify-start h-full">
            <p className="text-base xl:text-2xl text-gray-800 font-medium mt-4 text-center">
              There are currently no meetings in Test or Active state.
            </p>
            <p className="text-base xl:text-2xl text-grat-800 font-medium mt-2 text-center">
              Please go to your AgendaLink Setup to enable a meeting.
            </p>
            <button
              className="mt-4 group relative w-full flex justify-center py-2 px-4 border border-transparent text-xl font-medium rounded-md text-white bg-red-600 hover:bg-red-700"
              onClick={() => handleSignOut()}
            >
              Sign Out
            </button>
          </div>

          {/* Lower Menu Bar */}
          <div className="border-t-2 border-gray-800 w-full h-24 flex flex-nowrap justify-evenly"></div>
        </div>
        <div className=" mr-2 my-2 h-5\/6 bg-white rounded-lg p-2 flex flex-col items-center shadow-md">
          <p className="text-base xl:text-2xl border-b-2 border-gray-800 text-indigo-500 font-medium">
            Members
          </p>
          <div className="w-full overflow-y-auto flex flex-wrap"></div>
        </div>
      </div>
    );
  }

  if (votingResults.votes) {
    return <VotingResults />;
  }

  return (
    <div className="grid grid-cols-4 gap-2 overflow-hidden h-screen bg-gray-800">
      <div
        className={clsx(
          left ? "flex" : "hidden",
          "ml-2 my-2 h-7/8 bg-white rounded-lg p-2 flex-col items-center shadow-md overflow-hidden transform duration-500"
        )}
      >
        <TopicList
          sendEvent={sendEvent}
          nextTopic={nextTopic}
          timerStart={start}
          timerStop={stop}
        />
      </div>
      <div
        className={clsx(
          !left && !right && "col-span-4",
          !left && right && "col-span-3",
          left && !right && "col-span-3",
          left && right && "col-span-2",
          "my-2 h-7/8 bg-white rounded-lg p-2 flex flex-col items-center shadow-md justify-between overflow-hidden transform duration-500"
        )}
      >
        <div className="flex justify-evenly items-center w-full border-b-2 border-gray-800 py-1">
          <div
            className="flex items-center cursor-pointer text-black hover:text-blue-500 transform duration-200"
            onClick={() => setLeft(!left)}
          >
            {left ? (
              <BsChevronBarLeft className="h-5 2xl:h-7 w-5 2xl:w-7" />
            ) : (
              <BsChevronBarRight className="h-5 2xl:h-7 w-5 2xl:w-7" />
            )}
          </div>
          <p className="text-base xl:text-2xl text-indigo-500 font-medium">
            {soloSettings.entityName}
          </p>
          {user && (
            <>
              <p className="text-base xl:text-2xl text-gray-900 font-medium">
                {user.firstName} {user.lastName}
              </p>
              <p
                className="text-base xl:text-2xl text-red-900 font-semibold cursor-pointer border-2 border-red-900 py-1 px-2 rounded-md hover:border-red-400 hover:text-red-400"
                onClick={() => handleSignOut()}
              >
                Logout
              </p>
            </>
          )}
          {testMode && (
            <p className="text-base xl:text-2xl text-red-700">Test Mode</p>
          )}
          <div
            className="flex items-center justify-end cursor-pointer text-black hover:text-blue-500 transform duration-200"
            onClick={() => setRight(!right)}
          >
            {right ? (
              <BsChevronBarRight className="h-5 2xl:h-7 w-5 2xl:w-7" />
            ) : (
              <BsChevronBarLeft className="h-5 2xl:h-7 w-5 2xl:w-7" />
            )}
          </div>
        </div>
        {choice === 2 && <TopicDetails sendEvent={sendEvent} />}
        {user.adminRole && choice === 3 && (
          <SpeakerTimer sendEvent={sendEvent} />
        )}

        {/* Lower Menu Bar */}
        <div className="border-t-2 border-gray-800 w-full h-16 xl:h-24 flex flex-nowrap justify-evenly">
          {user.adminRole && (
            <>
              <button
                className={`${
                  choice === 2 && "bg-blue-300 border-opacity-0"
                } border-2 border-gray-800 mb-1 mt-2 p-4 flex flex-col justify-center text-lg font-medium rounded-md `}
                onClick={() => {
                  setChoice(2);
                }}
              >
                Topics
              </button>
              <button
                className={`${
                  choice === 3 && "bg-blue-300 border-opacity-0"
                } border-2 border-gray-800 mb-1 mt-2 p-4 flex flex-col justify-center text-lg font-medium rounded-md `}
                onClick={() => {
                  setChoice(3);
                }}
              >
                Timer
              </button>
            </>
          )}

          {/* <button
            className={`${
              choice === 3 && "bg-blue-300 border-opacity-0"
            } border-2 border-gray-800 mb-1 mt-2 p-4 flex flex-col justify-center text-lg font-medium rounded-md `}
            onClick={() => {
              setChoice(3);
            }}
          >
            Content
          </button> */}
          {!user.adminRole && soloSettings.micMode === 1 && (
            <RequestToSpeak sendEvent={sendEvent} />
          )}
          {!user.adminRole && soloSettings.micMode === 2 && (
            <RequestAndMic sendEvent={sendEvent} />
          )}
          {!user.adminRole && soloSettings.micMode === 3 && (
            <MicOnly sendEvent={sendEvent} />
          )}
        </div>
      </div>
      <div
        className={clsx(
          right ? "flex" : "hidden",
          "mr-2 my-2 h-7/8 bg-white rounded-lg p-2 flex-col items-center shadow-md overflow-hidden transform duration-500"
        )}
      >
        <MicList sendEvent={sendEvent} />
      </div>
      {topicPreview && <ModalTopicDetails />}
      {user.adminRole && endingNotesModal && <EndingNotesModal />}
      <TimerActive show={speakerStatus} />
    </div>
  );
};

export default RemoteUser;
