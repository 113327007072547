import axios from "axios";

export const apiUserLogin = async (server, code, seat, board) => {
  try {
    const result = await axios
      .post(`${process.env.REACT_APP_URL}${server}/session/signin`, {
        pinCode: code,
        seat: seat,
        board: board,
      })
      .then(function (response) {
        return response.data.user;
      });
    return result;
  } catch (error) {
    console.log("login apiUserLogin");
    console.log(error);
    return {};
  }
};

export const apiManagerLogin = async (server, email, password, path) => {
  try {
    const result = await axios
      .post(`${process.env.REACT_APP_URL}${server}/api/users/manager`, {
        email,
        password,
        path,
      })
      .then(function (response) {
        if (response.status === 200) {
          const { data } = response.data;
          return data;
        } else {
          return "error";
        }
      });
    return result;
  } catch (error) {
    // console.log("error login apiManagerLogin");
    // console.log(error);
    return "error";
  }
};

export const apiAuthLogin = async (server, email, password, seat, board) => {
  try {
    const result = await axios
      .post(`${process.env.REACT_APP_URL}${server}/api/users/auth`, {
        email,
        password,
        seat,
        board,
      })
      .then(function (response) {
        if (response.status === 200) {
          const { data } = response.data;
          // console.log(data);
          return data;
        } else {
          return "error";
        }
      });
    return result;
  } catch (error) {
    // console.log("error login apiManagerLogin");
    // console.log(error);
    return "error";
  }
};

export const apiTestLogin = async (server, code, seat, board) => {
  try {
    const result = await axios
      .post(`${process.env.REACT_APP_URL}${server}/session/signintest`, {
        testCode: code,
        seat: seat,
        board: board,
      })
      .then(function (response) {
        return response.data.user;
      });
    return result;
  } catch (error) {
    console.log("login apiUserLogin");
    console.log(error);
    return {};
  }
};

export const apiUserLogout = async (server, user) => {
  try {
    const result = await axios
      .post(`${process.env.REACT_APP_URL}${server}/session/signout`, {
        id: user,
      })
      .then(function (response) {
        return response.data.data;
      });
    return result;
  } catch (error) {
    console.log("login apiUserLogin");
    console.log(error);
    return {};
  }
};

export const apiSetupLogout = async (server, user, token) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    await axios
      .get(
        `${process.env.REACT_APP_URL}${server}/api/users/logout/${user}`,
        config
      )
      .then(function (response) {
        // Doing nothing here
      });
  } catch (error) {
    console.log("login apiSetupLogout");
    console.log(error);
  }
};
