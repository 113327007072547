import axios from "axios";

export const apiGetUsers = async (server) => {
  try {
    const result = await axios
      .get(`${process.env.REACT_APP_URL}${server}/api/users`)
      .then(function (response) {
        return response.data.data;
      });
    return result;
  } catch (error) {
    console.log("error api members apiGetUsers");
    console.log(error);
    return { error: "issue" };
  }
};

export const apiCheckPin = async (server, pin) => {
  try {
    const result = await axios
      .get(`${process.env.REACT_APP_URL}${server}/api/users/pin/${pin}`)
      .then(function (response) {
        return response.data.data;
      });
    return result;
  } catch (error) {
    console.log("error api members apiCheckPin");
    console.log(error);
    return { error: "issue" };
  }
};

export const apiCheckTestPin = async (server, pin) => {
  try {
    const result = await axios
      .get(`${process.env.REACT_APP_URL}${server}/api/users/testpin/${pin}`)
      .then(function (response) {
        return response.data.data;
      });
    return result;
  } catch (error) {
    console.log("error api members apiCheckTestPin");
    console.log(error);
    return { error: "issue" };
  }
};

export const apiGetFilteredUsers = async (server, filter, board) => {
  try {
    const result = await axios
      .get(
        `${process.env.REACT_APP_URL}${server}/api/users/filters/${filter}`,
        {
          params: { board: board },
        }
      )
      .then(function (response) {
        return response.data.data;
      });
    return result;
  } catch (error) {
    console.log("error api members apiGetUsers");
    console.log(error);
    return { error: "issue" };
  }
};

export const apiDeleteUser = async (server, id, token) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const result = await axios
      .delete(`${process.env.REACT_APP_URL}${server}/api/users/${id}`, config)
      .then(function (response) {
        if (response.status === 200) {
          return "success";
        }
      });
    return result;
  } catch (error) {
    console.log("error api members apiDeleteUser");
    console.log(error);
    return "error";
  }
};

export const apiGetSingleUser = async (server, id) => {
  try {
    const result = await axios
      .get(`${process.env.REACT_APP_URL}${server}/api/users/${id}`)
      .then(function (response) {
        return response.data.data;
      });
    return result;
  } catch (error) {
    console.log("error api members apiGetUsers");
    console.log(error);
    return { error: "issue" };
  }
};

export const apiGetManualLogin = async (server, id, board) => {
  try {
    const result = await axios
      .get(
        `${process.env.REACT_APP_URL}${server}/api/users/board/${id}?board=${board}`
      )
      .then(function (response) {
        if (response.status === 200) {
          const { data } = response.data;
          return data;
        } else {
          return "error";
        }
      });
    return result;
  } catch (error) {
    console.log("error api members apiGetManualLogin");
    console.log(error);
    return "error";
  }
};

export const apiUpdateUser = async (server, id, data, token) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const result = await axios
      .put(
        `${process.env.REACT_APP_URL}${server}/api/users/${id}`,
        data,
        config
      )
      .then((response) => {
        if (response.status === 200) {
          return "success";
        }
      });
    return result;
  } catch (error) {
    console.log("error api members apiUpdateUser");
    console.log(error);
    return "error";
  }
};

export const apiUpdateUserBoard = async (server, id, data, token) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const result = await axios
      .put(
        `${process.env.REACT_APP_URL}${server}/api/users/board/${id}`,
        data,
        config
      )
      .then((response) => {
        if (response.status === 200) {
          return "success";
        }
      });
    return result;
  } catch (error) {
    console.log("error api members apiUpdateUserBoard");
    console.log(error);
    return "error";
  }
};

export const apiCreateUser = async (server, data) => {
  try {
    const result = await axios.post(
      `${process.env.REACT_APP_URL}${server}/api/users`,
      data
    );
    return result.data.data;
  } catch (error) {
    console.log("error api members apiUpdateUser");
    console.log(error);
    return { error: "issue" };
  }
};

export const apiCreateImport = async (server, data, token) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const result = await axios
      .post(
        `${process.env.REACT_APP_URL}${server}/api/users/import`,
        data,
        config
      )
      .then((response) => {
        if (response.status === 200) {
          return "success";
        } else {
          return "error";
        }
      });
    return result;
  } catch (error) {
    console.log("error api members apiCreateImport");
    console.log(error);
    return "error";
  }
};

export const apiLogin = async (server, data) => {
  try {
    const result = await axios.post(
      `${process.env.REACT_APP_URL}${server}/api/users/login`,
      data
    );
    return result.data.data;
  } catch (error) {
    console.log("error api members apiLogin");
    console.log(error);
    return { error: "issue" };
  }
};
