import axios from "axios";

export const apiGetSettings = async (token) => {
  try {
    const result = await axios
      .get(`https://${process.env.REACT_APP_MANAGER}/api/solos/${token}`, {
        headers: { Authorization: process.env.REACT_APP_SOLO_KEY },
      })
      .then(function (response) {
        const { data } = response.data;
        if (data) {
          return data;
        } else {
          return { error: "issue reported" };
        }
      });
    return result;
  } catch (error) {
    console.log("error api settings GetSettings");
    console.log(error);
    return { error: "issue" };
  }
};

export const apiBackendSync = async (server) => {
  try {
    await axios.get(`${process.env.REACT_APP_URL}${server}/checkin`).then(function (response) {
      // console.log(response);
    });
  } catch (error) {
    console.log("error api settings GetSettings");
    console.log(error);
  }
};

export const apiUpdateSettings = async (data, token) => {
  try {
    const result = await axios
      .post(
        `https://${process.env.REACT_APP_MANAGER}/api/solos/${token}`,
        data,
        {
          headers: { Authorization: process.env.REACT_APP_SOLO_KEY },
        }
      )
      .then(function (response) {
        const { success } = response.data;
        return success;
      });
    return result;
  } catch (error) {
    console.log("error api settings GetSettings");
    console.log(error);
    return { error: "issue" };
  }
};
