import axios from "axios";

export const apiGetNotes = async (server, id) => {
  try {
    const result = await axios
      .get(`${process.env.REACT_APP_URL}${server}/api/session/notes/${id}`)
      .then(function (response) {
        // console.log(response);
        return response.data.notes;
      });
    return result;
  } catch (error) {
    console.log("error api notes apiGetNotes");
    console.log(error);
    return { error: "issue" };
  }
};

export const apiGetEndingNotes = async (server, id) => {
  try {
    const result = await axios
      .get(`${process.env.REACT_APP_URL}${server}/api/session/${id}/notes`)
      .then(function (response) {
        // console.log(response);
        return response.data.data;
      });
    return result;
  } catch (error) {
    console.log("error api notes apiGetNotes");
    console.log(error);
    return { error: "issue" };
  }
};

export const apiGetFile = async (server, id) => {
  try {
    const result = await axios
      .get(`${process.env.REACT_APP_URL}${server}/api/session/file/${id}`)
      .then(function (response) {
        // console.log(response);
        return response.data.fileUrl;
      });
    return result;
  } catch (error) {
    console.log("error api notes apiGetNotes");
    console.log(error);
    return { error: "issue" };
  }
};

export const apiUpdateNotes = async (server, data, id, token) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const result = await axios
      .put(
        `${process.env.REACT_APP_URL}${server}/api/session/notes/${id}`,
        {
          notes: data,
        },
        config
      )
      .then(function (response) {
        if (response.status === 200) {
          return true;
        } else {
          return false;
        }
      });
    return result;
  } catch (error) {
    console.error("error in api notes apiUpdateNotes");
    console.error(error);
    return false;
  }
};

export const apiUpdateFile = async (server, data, id, token) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const result = await axios
      .put(
        `${process.env.REACT_APP_URL}${server}/api/session/file/${id}`,
        {
          fileUrl: data,
        },
        config
      )
      .then(function (response) {
        if (response.data.data === "success") {
          return true;
        } else {
          return false;
        }
      });
    return result;
  } catch (error) {
    console.log("error in api notes apiUpdateNotes");
    return false;
  }
};

export const apiAddSpeaker = async (server, data, id, token) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const result = await axios
      .post(
        `${process.env.REACT_APP_URL}${server}/api/session/speaker/${id}`,
        {
          speaker: data,
        },
        config
      )
      .then(function (response) {
        const result = response.data.data;
        if (result === "true") {
          return true;
        } else {
          return false;
        }
      });
    return result;
  } catch (error) {
    console.log("error in api notes apiAddSpeaker");
    return false;
  }
};

export const apiUpdateSpeaker = async (server, data, id, token) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const result = await axios
      .put(
        `${process.env.REACT_APP_URL}${server}/api/session/speaker/${id}`,
        {
          speaker: data,
        },
        config
      )
      .then(function (response) {
        const result = response.data.data;
        if (result === "true") {
          return true;
        } else {
          return false;
        }
      });
    return result;
  } catch (error) {
    console.log("error in api notes apiAddSpeaker");
    return false;
  }
};

export const apiDeleteSpeaker = async (server, data, id, token) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const result = await axios
      .delete(
        `${process.env.REACT_APP_URL}${server}/api/session/speaker/${id}/${data}`,
        config
      )
      .then(function (response) {
        const result = response.data.data;
        if (result === "true") {
          return true;
        } else {
          return false;
        }
      });
    return result;
  } catch (error) {
    console.log("error in api notes apiAddSpeaker");
    return false;
  }
};

export const apiEndingNotes = async (server, data, id, token) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const result = await axios
      .put(
        `${process.env.REACT_APP_URL}${server}/api/session/${id}/notes`,
        {
          notes: data,
        },
        config
      )
      .then(function (response) {
        const result = response.data.data;
        if (result === "success") {
          return true;
        } else {
          return false;
        }
      });
    return result;
  } catch (error) {
    console.log("error in api notes apiEndingNotes");
    return false;
  }
};
