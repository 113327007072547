import { atom } from "recoil";

export const sessionState = atom({
  key: "session",
  default: null,
});

export const topicState = atom({
  key: "topics",
  default: [],
});

export const activeTopicState = atom({
  key: "activeTopic",
  default: null,
});

export const activeDetailsState = atom({
  key: "activeDetails",
  default: null,
});

export const topicSpeakersState = atom({
  key: "topicSpeakers",
  default: [],
});

export const liveSpeakerState = atom({
  key: "liveSpeaker",
  default: null,
});

export const micState = atom({
  key: "mics",
  default: [],
});

export const voterState = atom({
  key: "voters",
  default: [],
});

export const countdownState = atom({
  key: "countdown",
  default: "",
});

export const durationState = atom({
  key: "duration",
  default: "0 min",
});

export const micRequestState = atom({
  key: "micRequest",
  default: null,
});

export const loggedInState = atom({
  key: "loggedIn",
  default: [],
});

export const motionStatusState = atom({
  key: "motionStatus",
  default: "",
});

export const motionedByState = atom({
  key: "motionedBy",
  default: [],
});

export const voteStatusState = atom({
  key: "voteStatus",
  default: false,
});

export const memberVotedState = atom({
  key: "memberVoted",
  default: null,
});

export const votingResultsState = atom({
  key: "votingResults",
  default: {},
});

export const fsVotingResultsState = atom({
  key: "fsVotingResults",
  default: {},
});

export const liveVotingResultsState = atom({
  key: "liveVotingResults",
  default: {},
});

export const enableLoginState = atom({
  key: "enableLogin",
  default: false,
});

export const submittedVotesState = atom({
  key: "submittedVotes",
  default: [],
});

export const rollCallState = atom({
  key: "rollCall",
  default: false,
});

export const liveRollCallState = atom({
  key: "liveRollCall",
  default: false,
});

export const meetingsListState = atom({
  key: "pendingMeetings",
  default: [],
});

export const motionListState = atom({
  key: "motionList",
  default: null,
});

export const activeMotionState = atom({
  key: "activeMotion",
  default: null,
});

export const consentTopicState = atom({
  key: "consentTopics",
  default: [],
});

export const endingNotesState = atom({
  key: "endingNotes",
  default: "",
});

export const sessionBoardState = atom({
  key: "sessionBoard",
  default: null,
});
