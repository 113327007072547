import React, { useState } from "react";
import { useRecoilState } from "recoil";
import {
  soloSettingsState,
  settingsChangedState,
} from "../atoms/settings.atom";

// api
import {
  apiUpdateSettings,
  apiBackendSync,
  apiGetSettings,
} from "../api/settings";
import { setSessionStorage } from "../helpers/storage";

// components
import Loading from "../components/Modals/Loading";
import OptionItem from "../components/Options/OptionItem";
import Entity from "../components/Options/Entity";
import InMeeting from "../components/Options/InMeeting";
import Motions from "../components/Options/Motions";
import PdfOptions from "../components/Options/PdfOptions";
import Boards from "../components/Options/Boards";
import Success from "../components/Shared/Success";
import Error from "../components/Shared/Error";

const choices = ["Entity", "Boards", "Motions", "In Meeting"];

const Options = ({ sendEvent }) => {
  const [soloSettings, setSoloSettings] = useRecoilState(soloSettingsState);
  const [changes, setChanges] = useRecoilState(settingsChangedState);
  const [loading, setLoading] = useState(false);
  const [option, setOption] = useState(0);
  const [notify, setNotify] = useState(false);
  const [errorNotify, setErrorNotify] = useState(false);
  const [message, setMessage] = useState({});

  async function handleSave() {
    setChanges(false);
    setLoading(true);
    const result = await apiUpdateSettings(
      soloSettings,
      process.env.REACT_APP_SOLO_ID
    );
    setLoading(false);
    if (result) {
      setMessage({
        heading: "Success",
        subheading: "Settings have been updated",
      });
      setNotify(true);
      setTimeout(async () => {
        setNotify(false);
        setMessage({});
        await apiBackendSync(soloSettings.backendServerUrl);
        sendEvent("newSettings", {
          updated: true,
        });
      }, [2000]);
    } else {
      setMessage({
        heading: "Error",
        subheading: "There was error updating the settings.",
      });
      setErrorNotify(true);
      setTimeout(() => {
        setNotify(false);
        setMessage({});
      }, [2000]);
    }
  }

  async function handleCancel() {
    setChanges(false);
    const settings = await apiGetSettings(process.env.REACT_APP_SOLO_ID);
    if (settings) {
      setSoloSettings(settings);
      setSessionStorage("config", settings);
    }
  }

  if (loading) {
    return <Loading />;
  } else {
    return (
      <div className="flex flex-col overflow-hidden h-screen">
        {/* HEADER */}
        <div className="w-full pt-4 border-b-2 max-w-7xl mx-auto px-2 md:px-4">
          <h1 className="text-lg xl:text-xl 2xl:text-2xl font-semibold text-gray-900">
            Options
          </h1>
          <div className="w-full flex items-end justify-between">
            <nav className="-mb-px flex space-x-8" aria-label="Tabs">
              {choices.map((choice, index) => (
                <OptionItem
                  name={choice}
                  key={index}
                  active={option}
                  index={index}
                  action={setOption}
                />
              ))}
            </nav>
            {changes && (
              <div className="flex items-end justify-end">
                <button
                  className="inline-flex justify-center py-2 px-4 border border-gray-800 hover:border-gray-600 text-sm font-medium rounded-md text-black hover:text-delete-btn focus:outline-none transform duration-200"
                  onClick={() => handleCancel()}
                >
                  Cancel
                </button>
                <button
                  className="ml-3 inline-flex justify-center py-2 px-4 text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-400 focus:outline-none transform duration-200"
                  onClick={() => handleSave()}
                >
                  Save
                </button>
              </div>
            )}
          </div>
        </div>
        {/* Main Content */}
        <div className="w-full h-full overflow-y-auto max-w-7xl mx-auto px-4 sm:px-6 md:px-8 pb-10">
          {option === 0 && <Entity save={handleSave} />}
          {option === 1 && <Boards server={soloSettings.backendServerUrl} />}
          {option === 2 && <Motions server={soloSettings.backendServerUrl} />}
          {option === 3 && <InMeeting save={handleSave} />}
          {option === 5 && <PdfOptions save={handleSave} />}
        </div>

        {notify && (
          <Success heading={message.heading} subheading={message.subheading} />
        )}
        {errorNotify && <Error subheading={message.subheading} />}
      </div>
    );
  }
};

export default Options;
