import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useRecoilState, useSetRecoilState, useRecoilValue } from "recoil";

import { useSocket } from "../hooks/useSocket";
import { getSessionStorage } from "../helpers/storage";
import { userState } from "../atoms/user.atom";
import { micControlsState } from "../atoms/controls.atom";
import {
  sessionState,
  topicState,
  micState,
  voterState,
  activeTopicState,
  rollCallState,
  motionListState,
  consentTopicState,
  endingNotesState,
  sessionBoardState,
} from "../atoms/session.atom";

import {
  testModeState,
  menuChoiceState,
  topicPreviewState,
  confirmEndState,
  speakerStatusState,
} from "../atoms/globals.atom";
import { soloSettingsState } from "../atoms/settings.atom";
//hooks
import { useAgendaTimer } from "../hooks/useAgendaTimer";
import { apiGetActiveSession } from "../api/meetings";

// components
import TopicList from "../components/InMeeting/TopicList";
import MicList from "../components/InMeeting/MicList";
import LoginModal from "../components/Login/ManagerLogin";
import Loading from "../components/Modals/Loading";
import TopicDetails from "../components/InMeeting/TopicDetails";
import SpeakerTimer from "../components/InMeeting/SpeakerTimer/SpeakerTimer";
import Present from "../components/InMeeting/Present/Present";
import CustomControls from "../components/InMeeting/CustomControls/CustomControls";
import RollCallModal from "../components/Modals/RollCallModal";
import ModalTopicDetails from "../components/Modals/ModalTopicDetails";
import ConfirmEndModal from "../components/Modals/ConfirmEndModal";
import NotesModal from "../components/InMeeting/NotesModal";
import TimerActiveManager from "../components/InMeeting/TimerActiveManager";

const MeetingManager = () => {
  let history = useHistory();
  const [showNotes, setShowNotes] = useState(false);
  const [netStatus, setNetStatus] = useState(true);
  const [user, setUser] = useRecoilState(userState);
  const [testMode, setTestMode] = useRecoilState(testModeState);
  const [choice, setChoice] = useRecoilState(menuChoiceState);
  const [sessionData, setSessionData] = useRecoilState(sessionState);
  const setTopicsState = useSetRecoilState(topicState);
  const setConsentTopics = useSetRecoilState(consentTopicState);
  const setMicsState = useSetRecoilState(micState);
  const setVoterState = useSetRecoilState(voterState);
  const setMotionList = useSetRecoilState(motionListState);
  const setEndingNotes = useSetRecoilState(endingNotesState);
  const setMicControls = useSetRecoilState(micControlsState);

  const activeTopic = useRecoilValue(activeTopicState);
  const soloSettings = useRecoilValue(soloSettingsState);
  const rollCall = useRecoilValue(rollCallState);
  const topicPreview = useRecoilValue(topicPreviewState);
  const [confirmEnd, setConfirmEnd] = useRecoilState(confirmEndState);
  const setSessionBoard = useSetRecoilState(sessionBoardState);
  const speakerStatus = useRecoilValue(speakerStatusState);
  const { sendEvent, nextTopic, managerReset } = useSocket(
    soloSettings.backendServerUrl,
    "/manager"
  );
  const { start, stop } = useAgendaTimer(sendEvent);

  React.useEffect(() => {
    window.addEventListener("offline", (event) => {
      setNetStatus(false);
    });
    window.addEventListener("online", (event) => {
      setNetStatus(true);
    });
    const stored = getSessionStorage("user");
    if (stored) {
      setUser(stored);
      handleUserLogin();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    async function fetchData() {
      try {
        const result = await apiGetActiveSession(soloSettings.backendServerUrl);
        // console.log(result);
        if (result !== "nothing") {
          const { avConnect, plugins } = getSessionStorage("config");
          if (plugins?.length > 0) {
            const micControls = JSON.parse(plugins);
            const conferoIndex = micControls.findIndex(
              (e) => e.id === "confero"
            );
            const plixusIndex = micControls.findIndex((e) => e.id === "plixus");
            if (conferoIndex >= 0 || plixusIndex >= 0) {
              setMicControls(true);
            }
          }
          if (avConnect) {
            setMicControls(true);
          }
          setTestMode(result.testMode);
          setTopicsState(result.actionTopics);
          setConsentTopics(result.consentTopics);
          setSessionData(result._id);
          setSessionBoard(result.board);
          if (!result.testMode) {
            sendEvent("managerLogin", {
              user: user._id,
            });
          }
          if (result.endingNotes) {
            if (result.endingNotes.length > 0) {
              setEndingNotes(result.endingNotes);
            }
          }
          let newArray = [];
          let newArray2 = [];
          let motionArray = [];
          for (const member of result.invited) {
            let data = {};
            data.id = member._id;
            data.photoUrl = member.photoUrl;
            data.name = member.firstName + " " + member.lastName;
            data.status = member.micStatus;
            data.seat = member.seat;
            data.loggedIn = member.loggedIn;
            data.district = member.district;
            data.requestOrder = member.requestOrder;
            data.adminRole = member.adminRole;
            data.managerRole = member.managerRole;
            data.title = member.title;
            newArray.push(data);
            if (member.votingRole) {
              let votingData = {};
              votingData.id = member._id;
              votingData._id = member._id;
              votingData.photoUrl = member.photoUrl;
              votingData.name = member.firstName + " " + member.lastName;
              votingData.voted = "none";
              votingData.loggedIn = member.loggedIn;
              votingData.adminRole = member.adminRole;
              votingData.district = member.district;
              newArray2.push(votingData);
            }
          }
          for (const motion of result.motionList) {
            motionArray.push(motion.name);
          }
          setMotionList(motionArray);
          sendEvent("started", {
            status: true,
          });
          setMicsState(newArray);
          setVoterState(newArray2);
        } else {
          setSessionData("noMeeting");
        }
      } catch (error) {
        console.error("error fetchData");
        console.error(error);
        setSessionData("noMeeting");
      }
    }
    if (user) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const handleUserLogin = async () => {
    // await fetchData();
  };

  const handleSignOut = async () => {
    history.push("/setup");
  };

  const handleEndMeeting = (action) => {
    switch (action.type) {
      case "cancel":
        setConfirmEnd(false);
        break;
      case "exit":
        setConfirmEnd(false);
        setUser();
        break;
      case "confirm":
        sendEvent("meetingEnded", {
          session: sessionData,
          topic: activeTopic,
          testMode,
        });
        setConfirmEnd(false);
        managerReset();
        // setSessionData();
        // setUser();
        history.push("/setup");
        break;
      default:
        console.log("shouldn't see this");
        break;
    }
  };

  if (!user) {
    return (
      <div className="relative">
        {!netStatus && (
          <div className="absolute inset-0 w-full bg-red-600 h-10 flex items-center justify-center z-50">
            <p className="text-black text-2xl text-center">Device is Offline</p>
          </div>
        )}
        <LoginModal login={handleUserLogin} />
      </div>
    );
  }
  if (user && !sessionData) {
    return <Loading />;
  }
  if (user && sessionData === "noMeeting") {
    return (
      <div className="relative">
        {!netStatus && (
          <div className="absolute inset-0 w-full bg-red-600 h-10 flex items-center justify-center">
            <p className="text-black text-2xl text-center">Device is Offline</p>
          </div>
        )}
        <div className="grid grid-cols-4 gap-2 overflow-hidden h-screen bg-gray-400 max-h-screen">
          <div className="ml-2 my-2 h-5\/6 max-h-3\.5 bg-white rounded-lg p-2 flex flex-col items-center shadow-md overflow-hidden">
            <p className="text-base xl:text-2xl border-b-2 border-gray-800 text-indigo-500 font-medium">
              Agenda Items
            </p>
            <div className="w-full overflow-y-auto"></div>
          </div>
          <div className="my-2 col-span-2 h-5\/6 bg-white rounded-lg p-2 flex flex-col items-center shadow-md justify-between">
            <p className="text-base xl:text-2xl border-b-2 border-gray-800 text-indigo-500 font-medium">
              AgendaLink Solo Meeting Manager
            </p>
            <div className="flex flex-col items-center justify-start h-full">
              <p className="text-base xl:text-2xl text-gray-800 font-medium mt-4 text-center">
                There are currently no meetings in Test or Active state.
              </p>
              <p className="text-base xl:text-2xl text-grat-800 font-medium mt-2 text-center">
                Please go to your AgendaLink Setup to enable a meeting.
              </p>
              <button
                className="mt-4 group relative w-full flex justify-center py-2 px-4 border border-transparent text-xl font-medium rounded-md text-white bg-red-600 hover:bg-red-700"
                onClick={() => handleSignOut()}
              >
                Sign Out
              </button>
            </div>

            {/* Lower Menu Bar */}
            <div className="border-t-2 border-gray-800 w-full h-24 flex flex-nowrap justify-evenly"></div>
          </div>
          <div className=" mr-2 my-2 h-5\/6 bg-white rounded-lg p-2 flex items-center">
            <p className="text-base xl:text-2xl border-b-2 border-gray-800 text-indigo-500 font-medium">
              Members
            </p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="relative">
      {!netStatus && (
        <div className="absolute inset-0 w-full bg-red-600 h-10 flex items-center justify-center z-50">
          <p className="text-black text-2xl text-center">Device is Offline</p>
        </div>
      )}
      <div className="grid grid-cols-4 gap-2 overflow-hidden h-screen bg-gray-800 max-h-screen">
        <TopicList
          sendEvent={sendEvent}
          nextTopic={nextTopic}
          timerStart={start}
          timerStop={stop}
        />
        <div className="my-2 col-span-2 h-5\/6 bg-white rounded-lg p-2 flex flex-col items-center shadow-md justify-between overflow-hidden">
          <div>
            <p className="text-base xl:text-2xl border-b-2 border-gray-800 text-indigo-500 font-medium">
              AgendaLink Solo Meeting Manager{" "}
              {testMode && <span className="text-red-700">Test Mode</span>}
            </p>
          </div>
          {choice === 1 && <TopicDetails sendEvent={sendEvent} />}
          {choice === 2 && <SpeakerTimer sendEvent={sendEvent} />}
          {choice === 3 && <Present />}
          {choice === 4 && <CustomControls sendEvent={sendEvent} />}

          {/* Lower Menu Bar */}
          <div className="border-t-2 border-gray-800 w-full h-16 xl:h-24 flex flex-nowrap justify-evenly">
            <button
              className={`${
                choice === 1 && "bg-blue-300 border-opacity-0"
              } border-2 border-gray-800 mb-1 mt-2 p-4 flex flex-col justify-center text-lg font-medium rounded-md hover:bg-blue-500 transform duration-300`}
              onClick={() => {
                if (activeTopic) {
                  setChoice(1);
                }
              }}
            >
              Topics
            </button>
            {activeTopic && (
              <button
                className={`${
                  showNotes && "bg-blue-300 border-opacity-0"
                } border-2 border-gray-800 mb-1 mt-2 p-4 flex flex-col justify-center text-lg font-medium rounded-md transform duration-300 hover:bg-blue-500`}
                onClick={() => {
                  setShowNotes(true);
                }}
              >
                Motion Notes
              </button>
            )}

            <button
              className={`${
                choice === 2 && "bg-blue-300 border-opacity-0"
              } border-2 border-gray-800 mb-1 mt-2 p-4 flex flex-col justify-center text-lg font-medium rounded-md hover:bg-blue-500 transform duration-300`}
              onClick={() => {
                setChoice(2);
              }}
            >
              Timer
            </button>
            {soloSettings.bsInput && (
              <button
                className={`${
                  choice === 3 && "bg-blue-300 border-opacity-0"
                } border-2 border-gray-800 mb-1 mt-2 p-4 flex flex-col justify-center text-lg font-medium rounded-md hover:bg-blue-500 transform duration-300`}
                onClick={() => {
                  setChoice(3);
                }}
              >
                Content
              </button>
            )}

            {soloSettings.customAvControl && (
              <button
                className={`${
                  choice === 4 && "bg-blue-300 border-opacity-0"
                } border-2 border-gray-800 mb-1 mt-2 p-4 flex flex-col justify-center text-lg font-medium rounded-md hover:bg-blue-500 transform duration-300`}
                onClick={() => {
                  setChoice(4);
                }}
              >
                AV Controls
              </button>
            )}
          </div>
        </div>
        <MicList sendEvent={sendEvent} manager />
        {rollCall && <RollCallModal sendEvent={sendEvent} />}
        {topicPreview && <ModalTopicDetails sendEvent={sendEvent} />}
        {confirmEnd && (
          <ConfirmEndModal action={handleEndMeeting} testMode={testMode} />
        )}
        {showNotes && (
          <NotesModal
            visible={showNotes}
            isVisible={setShowNotes}
            topic={activeTopic}
          />
        )}
        <TimerActiveManager show={speakerStatus} />
      </div>
    </div>
  );
};

export default MeetingManager;
