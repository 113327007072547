import React from "react";
import { useRecoilValue } from "recoil";
import { countdownState } from "../../../atoms/session.atom";
import { userState } from "../../../atoms/user.atom";

function SpeakerTimer({ sendEvent }) {
  const countdown = useRecoilValue(countdownState);
  const user = useRecoilValue(userState);
  return (
    <div className="flex flex-col h-full w-full items-center justify-start mx-2 divide-y divide-gray-200 mt-4">
      <p className="px-2 text-lg xl:text-2xl text-gray-700 leading-relaxed tracking-wide">
        Speaker Timer
      </p>
      <h1 className="text-2xl 2xl:text-4xl font-bold">{countdown}</h1>
      {(user?.adminRole || user?.managerRole) && (
        <div className="items-center justify-center flex flex-nowrap w-full pb-2 shadow-sm rounded-md">
          <button
            className="w-32 h-14 mr-4 relative inline-flex items-center justify-center px-4 py-2 rounded-md border border-green-800 bg-green-600 text-base xl:text-lg font-medium text-white hover:bg-gray-50 hover:text-gray-700 focus:outline-none mt-4"
            onClick={() => {
              sendEvent("serverSpeaker", {
                command: "start",
              });
            }}
          >
            Start
          </button>
          <button
            className="w-32 h-14 mr-4 relative inline-flex items-center justify-center px-4 py-2 border rounded-md border-gray-500 bg-white text-base xl:text-lg font-medium text-gray-700 hover:bg-gray-200 focus:outline-none mt-4"
            onClick={() => {
              sendEvent("serverSpeaker", {
                command: "add",
              });
            }}
          >
            +1 Minute
          </button>
          <button
            className="w-32 h-14 mr-4 relative inline-flex items-center justify-center px-4 py-2 rounded-md border border-red-800 bg-red-500 text-base xl:text-lg font-medium text-white hover:text-gray-700 hover:bg-gray-50 focus:outline-none mt-4"
            onClick={() => {
              sendEvent("serverSpeaker", {
                command: "stop",
              });
            }}
          >
            Stop
          </button>
          <button
            className="w-32 h-14 mr-4 relative inline-flex items-center justify-center px-4 py-2 rounded-md border border-gray-500 bg-white text-base xl:text-lg font-medium text-gray-700 hover:bg-gray-200 focus:outline-none  mt-4"
            onClick={() => {
              sendEvent("serverSpeaker", {
                command: "minus",
              });
            }}
          >
            -1 Minute
          </button>
          <button
            className="w-32 h-14 relative inline-flex items-center justify-center px-4 py-2 rounded-md border border-indigo-800 bg-indigo-500 text-base xl:text-lg font-medium text-white hover:text-gray-700 hover:bg-gray-50 focus:outline-none mt-4"
            onClick={() => {
              sendEvent("serverSpeaker", {
                command: "reset",
              });
            }}
          >
            Reset
          </button>
        </div>
      )}
    </div>
  );
}

export default SpeakerTimer;
