import React from "react";
import { motion } from "framer-motion";
import { useRecoilValue } from "recoil";
import { soloSettingsState } from "../../atoms/settings.atom";

import Loading from "./Loading";

function NoActiveLive() {
  const soloSettings = useRecoilValue(soloSettingsState);

  if (Object.entries(soloSettings).length === 0) {
    return <Loading />;
  }

  return (
    <div className="hidden h-screen xl:flex items-start justify-center 2xl:py-8 px-4 sm:px-6 lg:px-2 bg-gradient-to-b from-main-bg to-blueGray-800">
      <img
        src="/images/LogoBlue.png"
        className="h-12 2xl:h-20 w-auto absolute top-4 left-4"
        alt="agendalink"
      />
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.2 }}
        className="w-full space-y-2 2xl:mt-8"
      >
        <div>
          <h2 className="block mb-6 text-center text-4xl font-bold text-gray-50 italic">
            AgendaLink Solo
          </h2>

          <img
            className="mx-auto h-28 2xl:h-72 w-auto sm:mt-4 2xl:mt-0 bg-gray-50 rounded-md py-6 px-12"
            src={soloSettings.entityLogo}
            alt="logo"
          />

          {/* <h2 className="mt-2 text-6xl text-center font-bold text-indigo-700">
            No Active Meeting
          </h2> */}
        </div>

        <div className="items-center justify-evenly flex-col flex"></div>
      </motion.div>
    </div>
  );
}

export default NoActiveLive;
