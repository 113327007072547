import axios from "axios";

export const apiUpdateIntegrations = async (data) => {
  try {
    const result = await axios
      .put(
        `https://${process.env.REACT_APP_MANAGER}/api/solos/edge/${process.env.REACT_APP_SOLO_ID}`,
        data,
        {
          headers: { Authorization: process.env.REACT_APP_SOLO_KEY },
        }
      )
      .then(function (response) {
        if (response.status === 200) {
          const { success } = response.data;
          return success;
        } else {
          return "error";
        }
      });
    return result;
  } catch (error) {
    console.log("error api settings updateSettings");
    console.log(error);
    return "error";
  }
};
