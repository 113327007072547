/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from "react";
import { useRecoilValue } from "recoil";
import clsx from "clsx";

import { countdownState, liveSpeakerState } from "../../atoms/session.atom";
import { speakerTimeNumberState } from "../../atoms/globals.atom";

import { Transition } from "@headlessui/react";
import { MdTimer } from "react-icons/md";

function TimerActive({ show }) {
  const countdown = useRecoilValue(countdownState);
  const liveSpeaker = useRecoilValue(liveSpeakerState);
  const speakerTimerNumber = useRecoilValue(speakerTimeNumberState);

  return (
    <>
      {/* Global notification live region, render this permanently at the end of the document */}
      <div
        aria-live="assertive"
        className="pointer-events-none fixed inset-0 flex items-end px-4 sm:items-start sm:px-6"
      >
        <div className="flex w-full flex-col items-center space-y-4 sm:items-center">
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          <Transition
            show={show}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              className={clsx(
                speakerTimerNumber <= 30 &&
                  speakerTimerNumber > 10 &&
                  "bg-yellow-400",
                speakerTimerNumber <= 10 && "bg-red-500",
                "pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-blue-300 shadow-2xl"
              )}
            >
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <MdTimer
                      className="h-6 2xl:h-8 w-6 2xl:w-8 text-indigo-800"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm 2xl:text-base hd:text-lg text-gray-900">
                      Timer is Active...
                    </p>
                    <p className="mt-1 2xl:text-xl text-gray-800 font-medium">
                      {countdown}
                    </p>
                    {liveSpeaker && liveSpeaker.firstName && (
                      <p className="mt-1 2xl:text-xl hd:text-2xl text-gray-800 font-medium">
                        {liveSpeaker.firstName} {liveSpeaker.lastName}
                      </p>
                    )}
                    {liveSpeaker && !liveSpeaker.firstName && (
                      <p className="mt-1 2xl:text-xl hd:text-2xl text-gray-800 font-medium">
                        {liveSpeaker}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  );
}

export default TimerActive;
