/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState, useEffect } from "react";
import { useRecoilValue } from "recoil";
import {
  voterState,
  activeTopicState,
  activeMotionState,
} from "../../atoms/session.atom";
import { testModeState } from "../../atoms/globals.atom";

import { Dialog, Transition } from "@headlessui/react";
import { ChatAlt2Icon } from "@heroicons/react/outline";

import HeadlessDrop from "../Shared/DropDown/GridMotionDrop";

const MotionModal = ({ type, visible, show, sendEvent, motionedBy }) => {
  const voters = useRecoilValue(voterState);
  const activeTopic = useRecoilValue(activeTopicState);
  const testMode = useRecoilValue(testModeState);
  const activeMotion = useRecoilValue(activeMotionState);
  const [first, setFirst] = useState({ id: "none", name: "Please Choose" });
  const [second, setSecond] = useState({ id: "none", name: "Please Choose" });

  useEffect(() => {
    if (motionedBy.length === 1) {
      setFirst({
        id: motionedBy[0]._id,
        name: motionedBy[0].firstName + " " + motionedBy[0].lastName,
      });
    }
    if (motionedBy.length === 2) {
      setSecond({
        id: motionedBy[1]._id,
        name: motionedBy[1].firstName + " " + motionedBy[1].lastName,
      });
    }
    if (motionedBy.length === 0) {
      setFirst({ id: "none", name: "Please Choose" });
      setSecond({ id: "none", name: "Please Choose" });
    }
  }, [motionedBy]);

  function handleMotion(target, value) {
    if (target === "motionedby") {
      setFirst(value);
      sendEvent("newMotion", {
        topic: activeTopic,
        member: value.id,
        level: 1,
        test: testMode,
        motionType: type,
      });
    } else {
      setSecond(value);
      sendEvent("newMotion", {
        topic: activeTopic,
        member: value.id,
        level: 2,
        test: testMode,
        motionType: type,
        motion: activeMotion,
      });
    }
  }

  function handleCancel() {
    if (activeMotion) {
      sendEvent("deleteMotion", {
        topic: activeTopic,
        motion: activeMotion,
      });
    }
    show(false);
    setTimeout(() => {
      setFirst({ id: "none", name: "Please Choose" });
      setSecond({ id: "none", name: "Please Choose" });
    }, 2000);
  }

  return (
    <Transition.Root show={visible} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-10 inset-0 overflow-y-auto"
        open={visible}
        onClose={() => show(false)}
      >
        <div className="items-end justify-center min-h-screen text-center block p-0">
          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen mt-40 2xl:m-0"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block space-y-4 bg-gradient-to-b from-gray-200 to-gray-400 rounded-lg text-left overflow-visible shadow-xl transform transition-all sm:max-w-xl sm:w-full sm:p-6">
              <div>
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-white">
                  <ChatAlt2Icon
                    className="h-6 w-6 text-gray-600"
                    aria-hidden="true"
                  />
                </div>
                <div className="text-center mt-4">
                  <Dialog.Title
                    as="h3"
                    className="text-lg leading-6 font-medium text-gray-900"
                  >
                    Motion to {type}
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-black">
                      Please choose motioners
                    </p>
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-2 gap-4">
                <HeadlessDrop
                  array={voters}
                  value={first}
                  change={handleMotion}
                  heading="Motioned By"
                />
                {activeMotion && (
                  <HeadlessDrop
                    array={voters}
                    value={second}
                    change={handleMotion}
                    heading="Seconded By"
                  />
                )}
              </div>
              <div className="grid grid-cols-2 gap-2">
                <button
                  type="button"
                  className="mt-4 inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-opacity-70 focus:outline-none sm:text-sm"
                  onClick={() => handleCancel()}
                >
                  Cancel Motions
                </button>
                <button
                  type="button"
                  className={`${
                    motionedBy.length === 2 ? "block" : "hidden"
                  } mt-4 inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-700 text-base font-medium text-white hover:bg-opacity-70 focus:outline-none sm:text-sm`}
                  onClick={() => show(false)}
                >
                  Confirm
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default MotionModal;
