import React, { useState, Fragment } from "react";
import Dropzone from "react-dropzone";
import firebase from "../../firebase/firebase";
import { useRecoilValue } from "recoil";

import { soloSettingsState } from "../../atoms/settings.atom";
import { managerUserState } from "../../atoms/user.atom";
import {
  apiUpdateFile,
  apiAddSpeaker,
  apiUpdateSpeaker,
  apiDeleteSpeaker,
} from "../../api/notes";
import { apiUpdateTopic, apiRemoveTopic } from "../../api/topics";

import { Transition, Menu } from "@headlessui/react";
import { DotsVerticalIcon } from "@heroicons/react/solid";
import { ChatIcon } from "@heroicons/react/outline";
import { FiTrash2, FiEdit } from "react-icons/fi";
import { BiMessageSquareEdit } from "react-icons/bi";
import { GrAttachment } from "react-icons/gr";

import SpeakerCards from "../Modals/SpeakerCards";
import Annotate from "../Modals/Annotate";
import FileUrl from "../Modals/FileUrl";
import SpeakerModal from "../Modals/SpeakerModal";
import EditSpeakerModal from "../Modals/EditSpeakerModal";
import EditTopicSlideOut from "../Topics/EditTopicSlideOut";
import Loading from "../Shared/Loading";

function ActiveTopicCard({ topic, refresh }) {
  const user = useRecoilValue(managerUserState);
  const [show, setShow] = useState(false);
  const [annotate, setAnnotate] = useState(false);
  const [fileUrl, setFileUrl] = useState(false);
  const [speakerModal, setSpeakerModal] = useState(false);
  const [editSpeakerModal, setEditSpeakerModal] = useState(false);
  const [editSpeaker, setEditSpeaker] = useState();
  const soloSettings = useRecoilValue(soloSettingsState);
  const [showEdit, setShowEdit] = useState(false);
  const [loading, setLoading] = useState(false);

  let count = 0;
  if (topic.speakers) {
    count = topic.speakers.length;
  }

  function handleOption(option) {
    switch (option) {
      case "anno":
        setAnnotate(true);
        break;
      case "file":
        setFileUrl(true);
        break;
      case "comment":
        setEditSpeakerModal(false);
        setSpeakerModal(true);
        break;
      default:
        console.log("screwed up somewhere");
        break;
    }
  }

  function handleFileUpload(files) {
    try {
      var storageRef = firebase
        .storage()
        .ref(`${soloSettings.entityName}/${topic._id}/${files[0].name}`);
      var status = storageRef.put(files[0]);
      status.on(
        "state_changed",
        function progress(snapshot) {
          var progress = ~~(
            (snapshot.bytesTransferred / snapshot.totalBytes) *
            100
          );
          if (progress > 0) {
            setLoading(true);
          }
        },
        function error(err) {
          console.error("error in handleFileUpload");
          console.error(err);
        },
        function complete() {
          // get url of file
          status.snapshot.ref.getDownloadURL().then(async (downloadURL) => {
            const result = await apiUpdateFile(
              soloSettings.backendServerUrl,
              downloadURL,
              topic._id,
              user?.token
            );
            setLoading(false);
            refresh(result);
          });
        }
      );
    } catch (error) {
      console.error(error);
    }
  }

  function clear() {
    setFileUrl(false);
    setAnnotate(false);
  }

  function handleSave(status) {
    refresh(status);
    setAnnotate(false);
    setFileUrl(false);
  }

  async function handleAddSpeaker(action, data) {
    if (action === "save") {
      setSpeakerModal(false);
      const result = await apiAddSpeaker(
        soloSettings.backendServerUrl,
        data.speaker,
        topic._id,
        user?.token
      );
      refresh(result);
    } else {
      setSpeakerModal(false);
    }
  }

  async function handleSpeakerUpdate(action, data) {
    switch (action) {
      case "edit":
        setEditSpeaker(data);
        setEditSpeakerModal(true);
        break;
      case "delete":
        const deleteResult = await apiDeleteSpeaker(
          soloSettings.backendServerUrl,
          data._id,
          topic._id,
          user?.token
        );
        refresh(deleteResult);
        break;
      case "save":
        setEditSpeakerModal(false);
        const result = await apiAddSpeaker(
          soloSettings.backendServerUrl,
          data.speaker,
          topic._id,
          user?.token
        );
        refresh(result);
        break;
      case "update":
        setEditSpeakerModal(false);
        const updateResult = await apiUpdateSpeaker(
          soloSettings.backendServerUrl,
          data.speaker,
          topic._id,
          user?.token
        );
        refresh(updateResult);
        break;
      default:
        setEditSpeakerModal(false);
        break;
    }
  }

  async function handleTopicUpdate(topic) {
    setShowEdit(false);
    const result = await apiUpdateTopic(
      soloSettings.backendServerUrl,
      {
        title: topic.title,
        itemNumber: topic.itemNumber,
        more: topic.more,
        actionEnabled: topic.actionEnabled,
      },
      topic._id,
      user?.token
    );
    if (result !== "error") {
      refresh(true);
    } else {
      refresh(false);
    }
  }

  async function handleTopicDelete() {
    let granicus = false;
    if (topic.eventId) {
      granicus = true;
    }
    const result = await apiRemoveTopic(
      soloSettings.backendServerUrl,
      topic._id,
      user?.token,
      granicus
    );
    if (result !== "error") {
      refresh(true);
    } else {
      refresh(false);
    }
  }

  return (
    <div>
      <div className="relative h-full border-2 border-gray-400 rounded-lg shadow-md p-4 mb-8">
        <div className="flex justify-between relative">
          <h2 className="tracking-widest text-xs title-font font-medium text-gray-500 mb-1">
            {topic.title}
          </h2>
          <Menu as="div" className="relative">
            <div>
              <Menu.Button className="rounded-full flex items-center text-gray-900 hover:text-gray-900 hover:border-gray-900 focus:outline-none">
                <DotsVerticalIcon className="h-5 w-5 2xl:h-6 2xl:w-6 text-black" />
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-50">
                <Menu.Item
                  className="cursor-pointer inline-flex items-center space-x-2 px-4 py-2 text-base text-gray-700 hover:bg-gray-100 hover:text-gray-900 w-full"
                  onClick={() => setShowEdit(true)}
                >
                  <div>
                    <FiEdit className="w-5 h-5" />
                    <p>Edit</p>
                  </div>
                </Menu.Item>
                <Menu.Item
                  className="cursor-pointer inline-flex items-center space-x-2 px-4 py-2 text-base text-gray-700 hover:bg-gray-100 hover:text-gray-900 w-full"
                  onClick={() => handleOption("anno")}
                >
                  <div>
                    <BiMessageSquareEdit className="w-5 h-5" />
                    <p>Annotate</p>
                  </div>
                </Menu.Item>
                <Menu.Item>
                  <Dropzone
                    onDrop={(e) => handleFileUpload(e)}
                    multiple={false}
                    accept=".pdf"
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div {...getRootProps()}>
                        <button
                          type="button"
                          className="inline-flex items-center space-x-2 px-4 py-2 text-base text-gray-700 hover:bg-gray-100 hover:text-gray-900 w-full"
                        >
                          <GrAttachment className="w-5 h-5" />
                          <p>Attach PDF</p>
                        </button>
                        <input {...getInputProps()} />
                      </div>
                    )}
                  </Dropzone>
                </Menu.Item>
                <Menu.Item
                  className="cursor-pointer inline-flex items-center space-x-2 px-4 py-2 text-base text-gray-700 hover:bg-gray-100 hover:text-gray-900 w-full"
                  onClick={() => handleOption("comment")}
                >
                  <div>
                    <ChatIcon className="w-5 h-5" />
                    <p>Add Speaker</p>
                  </div>
                </Menu.Item>
                <Menu.Item
                  className="border-t border-gray-900 mt-3 cursor-pointer inline-flex items-center space-x-2 px-4 py-2 text-base text-gray-700 hover:bg-gray-100 hover:text-gray-900 w-full"
                  onClick={() => handleTopicDelete()}
                >
                  <div>
                    <FiTrash2 className="w-5 h-5" />
                    <p>Delete</p>
                  </div>
                </Menu.Item>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>

        <h1 className="title-font text-sm xl:text-lg font-medium text-gray-900 mb-1 xl:mb-3">
          {topic.itemNumber}
        </h1>
        <p className="leading-relaxed mb-3 text-sm xl:text-base">
          {topic.more}
        </p>

        <div className="flex items-center space-x-4">
          <span className="text-green-900 inline-flex items-center leading-none text-sm xl:text-lg">
            <svg
              className="w-4 h-4 xl:w-5 xl:h-5 mr-1"
              stroke="currentColor"
              strokeWidth="2"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              viewBox="0 0 24 24"
            >
              <path d="M21 11.5a8.38 8.38 0 01-.9 3.8 8.5 8.5 0 01-7.6 4.7 8.38 8.38 0 01-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 01-.9-3.8 8.5 8.5 0 014.7-7.6 8.38 8.38 0 013.8-.9h.5a8.48 8.48 0 018 8v.5z"></path>
            </svg>
            {count}
          </span>
          {count > 0 && (
            <>
              <span className="text-gray-600 mr-3 inline-flex items-center lg:ml-auto md:ml-0 ml-auto leading-none text-sm pr-3 py-1"></span>
              <button
                type="button"
                className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-blue-400 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onClick={() => setShow(true)}
              >
                View Speaker Cards
              </button>
            </>
          )}
          <div className="flex items-center space-x-4">
            {topic.notes?.length > 0 && (
              <div className="flex items-center flex-wrap">
                <span className="inline-flex items-center px-2 py-1 rounded text-xs font-medium bg-green-500 text-gray-50">
                  Notes
                </span>
              </div>
            )}
            {topic.fileUrl.length > 0 && (
              <div className="flex items-center flex-wrap">
                <span className="inline-flex items-center px-2 py-1 rounded text-xs font-medium bg-indigo-400 text-gray-50">
                  PDF
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
      {show && (
        <SpeakerCards
          cards={topic.speakers}
          setShow={setShow}
          update={handleSpeakerUpdate}
        />
      )}
      {annotate && (
        <Annotate clear={clear} topic={topic._id} save={handleSave} />
      )}
      {showEdit && (
        <EditTopicSlideOut
          currentTopic={topic}
          isVisible={setShowEdit}
          updateTopic={handleTopicUpdate}
        />
      )}
      {fileUrl && <FileUrl clear={clear} topic={topic._id} save={handleSave} />}
      {speakerModal && <SpeakerModal action={handleAddSpeaker} topic={topic} />}
      {editSpeakerModal && (
        <EditSpeakerModal
          action={handleSpeakerUpdate}
          topic={topic}
          activeSpeaker={editSpeaker}
        />
      )}
      {loading && <Loading />}
    </div>
  );
}

export default ActiveTopicCard;
