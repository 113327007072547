import { Fragment } from "react";
import Dropzone from "react-dropzone";
import { Menu, Transition } from "@headlessui/react";
import { CloudUploadIcon } from "@heroicons/react/outline";

const ImportMenu = ({ handleImport }) => {
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="inline-flex items-center px-4 py-2 xl:px-6 xl:py-3 rounded-md text-sm font-medium text-white bg-indigo-600 hover:bg-opacity-70 focus:outline-none mb-2 transform duration-300">
          Import File
          <CloudUploadIcon
            className="w-5 2xl:h-6 2xl:w-6 h-5 ml-2 -mr-1 text-white-200 hover:text-white-100"
            aria-hidden="true"
          />
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute w-40 2xl:w-52 origin-top-right px-2 py-2 bg-gray-200 divide-y divide-gray-300 rounded-md shadow-2xl focus:outline-none border border-indigo-600 flex flex-col space-y-2 z-50">
          <Menu.Item className="text-sm xl:text-base 2xl:text-lg cursor-pointer">
            <Dropzone
              onDrop={(e) => handleImport("csv", e)}
              multiple={false}
              accept="text/csv"
            >
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps()}>
                  <button
                    type="button"
                    className="px-4 py-2 w-full h-full inline-flex items-center text-sm 2xl:text-base font-medium text-black focus:outline-none hover:bg-indigo-200"
                  >
                    CSV File
                  </button>
                  <input {...getInputProps()} />
                </div>
              )}
            </Dropzone>
          </Menu.Item>
          <Menu.Item className="text-sm xl:text-base 2xl:text-lg cursor-pointer">
            <Dropzone
              onDrop={(e) => handleImport("boardbook", e)}
              multiple={false}
              accept="text/html"
            >
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps()}>
                  <button
                    type="button"
                    className="px-4 py-2 w-full h-full inline-flex items-center text-sm 2xl:text-base font-medium text-black focus:outline-none hover:bg-indigo-200"
                  >
                    BoardBook HTML
                  </button>
                  <input {...getInputProps()} />
                </div>
              )}
            </Dropzone>
          </Menu.Item>
          <Menu.Item className="text-sm xl:text-base 2xl:text-lg cursor-pointer">
            <Dropzone
              onDrop={(e) => handleImport("civicplus", e)}
              multiple={false}
              accept="text/html"
            >
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps()}>
                  <button
                    type="button"
                    className="px-4 py-2 w-full h-full inline-flex items-center text-sm 2xl:text-base font-medium text-black focus:outline-none hover:bg-indigo-200"
                  >
                    CivicPlus HTML
                  </button>
                  <input {...getInputProps()} />
                </div>
              )}
            </Dropzone>
          </Menu.Item>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default ImportMenu;
